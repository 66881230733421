import { SETTING_KEY, REVERSE_SETTING_KEY } from 'shared/constants';
import {
  postSettings, getSettings,
} from 'shared/services/api';
// import utils from 'shared/helper/utils';
import ACTION_TYPES from './actionTypes';

/**
 * To store general alert setting  (specifically: isReceiveEmailNotification)
 */
const toggleGeneralSetting = () => ({
  type: ACTION_TYPES.TOGGLE_ALERT_GENERAL_SETTING,
});
const toggleDailyBriefing = () => ({
  type: ACTION_TYPES.TOGGLE_ALERT_DAILY_BRIEFING,
});

const toggleMorningStarRating = () => ({
  type: ACTION_TYPES.SAVE_MORNING_START_RATING,
});
const toggleResearchPublication = () => ({
  type: ACTION_TYPES.SAVE_RESEARCH_PUBLICATION,
});
const togglePublicationReports = isReportsActive => ({
  type: ACTION_TYPES.SAVE_PUBLICATION_REPORTS,
  isReportsActive,
});
const togglePublicationNotes = isNotesActive => ({
  type: ACTION_TYPES.SAVE_PUBLICATION_NOTES,
  isNotesActive,
});
const togglePublicationFairValue = () => ({
  type: ACTION_TYPES.SAVE_PUBLICATION_FAIR_VALUE,
});
const togglePublicationUncertainty = () => ({
  type: ACTION_TYPES.SAVE_PUBLICATION_UNCERTAINTY,
});
const togglePublicationEconomicMoat = () => ({
  type: ACTION_TYPES.SAVE_PUBLICATION_ECONOMIC_MOAT,
});

const alertLoading = isAlertLoading => ({
  type: ACTION_TYPES.ALERT_SETTING_LOADING,
  payload: { isAlertLoading },
});

/**
 * Set first alert setting to true
 */
const setFirstAlertSetting = () => ({
  type: ACTION_TYPES.SET_FIRST_ALERT_SETTINGS,
});

/**
 * To store alert setting
 */
const saveAlertSettings = e => (dispatch, getState) => {
  // const { isAlertSettingSetup } = getState().alertsReducer;
  // if (!isAlertSettingSetup) dispatch(setFirstAlertSetting());
  dispatch({
    type: ACTION_TYPES.ALERT_SETTINGS_SAVE,
    payload: {
      isReceiveEmailNotification: e.isReceiveEmailNotification,
      isReceiveDailyBriefing: e.isReceiveDailyBriefing,
      isMorningStarRatingActive: e.isMorningStarRatingActive,
      isResearchPublicationActive: e.isResearchPublicationActive,
      isReportsActive: e.isReportsActive,
      isNotesActive: e.isNotesActive,
      isFairValueActive: e.isFairValueActive,
      isUncertaintyActive: e.isUncertaintyActive,
      isEconomicMoatActive: e.isEconomicMoatActive,
    },
  });
};

/**
 * @todo: update this to submit data (saved general & alert setting) to our backend
 * use { isReceiveEmailNotification, alertSetting } = getState()
 */
const submitAlertSettings = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const { alertsReducer: { alertSettings } } = getState();
  // console.log('submission >>> ', isReceiveEmailNotification, alertSettings, Object.keys(alertSettings));
  const alertKeys = Object.keys(alertSettings);
  const data = [];
  if (alertKeys && Array.isArray(alertKeys)) {
    alertKeys.map(field => data.push({
      msgCode: SETTING_KEY[field],
      enabled: alertSettings[field],
    }));
  }

  postSettings(data)
    .then((res) => {
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    });
});

const getSavedAlertSettings = () => (dispatch) => {
  dispatch(alertLoading(true));
  getSettings()
    .then((res) => {
      // console.log('get success ', res.settings);
      const savedSettingsFrBackend = res.settings;
      const data = savedSettingsFrBackend && savedSettingsFrBackend.reduce((obj, item) => {
        obj[REVERSE_SETTING_KEY[item.msgCode]] = item.enabled;
        return obj;
      }, {});
      // console.log('new settings ', Object.assign({}, data));
      if (data && Object.keys(data).length) {
        dispatch(saveAlertSettings(data));
      }
      if (res && res.settings && res.settings.length !== 0) dispatch(setFirstAlertSetting());
      dispatch(alertLoading(false));
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.ALERT_SETTINGS_GET_FAILURE, payload: { error: err } });
      dispatch(alertLoading(false));
    });
};


const alerts = {
  toggleGeneralSetting,
  toggleDailyBriefing,
  saveAlertSettings,
  submitAlertSettings,
  setFirstAlertSetting,
  getSavedAlertSettings,

  toggleMorningStarRating,
  toggleResearchPublication,
  togglePublicationReports,
  togglePublicationNotes,
  togglePublicationFairValue,
  togglePublicationUncertainty,
  togglePublicationEconomicMoat,
};

export default alerts;
