import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.scss';
import { Icon } from 'components';

const Button = ({
  type, name, value, className, isOutlineButton, onButtonClick, isPrimary, isSecondary,
  isDisabled, isTertiary, isBig, isSmall, isLink, rtl, isIcon, isRounded,
}) => {
  const classes = classNames({
    'nw-button': true,
    'nw-button--primary': isPrimary,
    'nw-button--secondary': isSecondary,
    'nw-button--tertiary': isTertiary,
    'nw-button--outline': isOutlineButton,
    'nw-button--disabled': isDisabled,
    'nw-button--big': isBig,
    'nw-button--small': isSmall,
    'nw-button--link': isLink,
    'nw-button--rtl': rtl,
    'nw-button--icon': isIcon,
    'nw-button--rounded': isRounded,
  });
  return (
  // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      name={name}
      className={`${classes} ${className}`}
      onClick={onButtonClick}
      disabled={isDisabled}
    >
      {isIcon ? <Icon name={name} /> : value}
    </button>
  );
};

Button.propTypes = {
  /** button's type */
  type: PropTypes.oneOf(['button', 'submit']),
  /** Button name */
  name: PropTypes.string,
  /** use as button label */
  value: PropTypes.node,
  /** by default, value is false so button can be primary otherwise, it's outline button */
  isOutlineButton: PropTypes.bool,
  /** passing click event as props */
  onButtonClick: PropTypes.func,
  /** is button secondary */
  isPrimary: PropTypes.bool,
  /** is button secondary */
  isSecondary: PropTypes.bool,
  /** is button tertiary */
  isTertiary: PropTypes.bool,
  /** is button a link */
  isLink: PropTypes.bool,
  /** Is button disabled */
  isDisabled: PropTypes.bool,
  /** is button big */
  isBig: PropTypes.bool,
  /** is button big */
  isSmall: PropTypes.bool,
  /** is RTL */
  rtl: PropTypes.bool,
  /** is button an icon */
  isIcon: PropTypes.bool,
  /** is button rounded */
  isRounded: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  name: null,
  value: null,
  isOutlineButton: false,
  onButtonClick: () => { },
  isPrimary: false,
  isSecondary: false,
  isTertiary: false,
  isLink: false,
  isDisabled: false,
  isBig: false,
  isSmall: false,
  rtl: false,
  isIcon: false,
  isRounded: false,
};

export default Button;
