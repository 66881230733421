import React from 'react';

const EmptyRows = () => (
  <div className="nw-stock-list__emptyContainer__empty-rows">
    <h3>Currently, you are not monitoring any stocks!</h3>
    <p>
      <span>
        You can start monitoring stocks and get alerted immediately for changes
        <br />
        by searching above or through
      </span>
      <strong>Morningstar Equity Research Portal</strong>
    </p>
  </div>
);

export default EmptyRows;
