export const ROLE = {
  ADVISOR: 'advisor',
  POWERUSER: 'poweruser',
};

export const TRACKING_EVENT = {
  SELECT: 'select',
  ACCESS_PAGE: 'access_page',
  PAYLOAD: 'payload',
  TYPING: 'typing',
  ADD_STOCKS: 'add_stock',
  MY_ALERT: 'my_alerts',
  STOCK_DETAIL: 'stock_details',
};

export const TRACKING_KEY = {
  DEVICE: 'device',
  BROWSER: 'browser',
  COUNTRY: 'country',
  ROLES: 'roles',
  DBOFFICE: 'dbOffice',
  PAGE: 'page',
  ADD_STOCK_FROM_INPUT: 'add_stock_from_input',
  ADD_STOCK_BY_SECTOR: 'add_stock_by_sector',
  ADD_STOCK_FROM_MS_TOOL: 'add_stock_from_ms_tool',
  SEARCH_STOCKS: 'search_stocks',
  ACTIVE_DEPARTMENT: 'active_department',
  EMAIL_CLICKED: 'email_clicked',
};

export const TRACKING_VALUE = {
  MY_ALERT: 'my_alert',
};

export const END_POINT = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  USERS: '/users',
  SIGNOUT: '/signout',
  SMART_ALERT_API: '/smart-alert-api',
  SMART_CLIENT_DATA_API: '/smart-client-data-api',
  SSO: '/sso',
};

export const PATH = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  HOME: '/',
  ALERT: '/my-alerts',
  STOCK: '/stock',
};

export const STORAGE_KEY = {
  USER_INFO: 'USER_INFO',
};

/** Key to be saved on backend */
export const SETTING_KEY = {
  isReceiveEmailNotification: 'db.notification.new_stock_coverage',
  isReceiveDailyBriefing: 'db.notification.daily_brief',
  isMorningStarRatingActive: 'db.notification.morning_star_rating',
  isResearchPublicationActive: 'db.notification.publication',
  isReportsActive: 'db.notification.publication.report',
  isNotesActive: 'db.notification.publication.note',
  isFairValueActive: 'db.notification.publication.fair_value',
  isUncertaintyActive: 'db.notification.publication.uncertainty',
  isEconomicMoatActive: 'db.notification.publication.economic_moat',
};

/** Key to be saved on frontend */
export const REVERSE_SETTING_KEY = {
  'db.notification.new_stock_coverage': 'isReceiveEmailNotification',
  'db.notification.morning_star_rating': 'isMorningStarRatingActive',
  'db.notification.publication': 'isResearchPublicationActive',
  'db.notification.publication.report': 'isReportsActive',
  'db.notification.publication.note': 'isNotesActive',
  'db.notification.publication.fair_value': 'isFairValueActive',
  'db.notification.publication.uncertainty': 'isUncertaintyActive',
  'db.notification.publication.economic_moat': 'isEconomicMoatActive',
  'db.notification.daily_brief': 'isReceiveDailyBriefing',
};
