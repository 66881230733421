import React from 'react';
import Logo from 'assets/images/logo-with-title@2x.png';
import morningStar from 'assets/images/Image 2@2x.png';
import './LayoutAuth.scss';

const LayoutAuth = Wrapped => props => (
  <div className="db-auth-page">
    <section>
      <aside className="db-auth-page__sideHeader">
        <div>
          <img src={Logo} alt="Deutsche Bank" />
          <h2 className="tool-title">
Morningstar
            <br />
            {' '}
Equity Research Portal
          </h2>
        </div>
      </aside>

      <main className="db-auth-page__content">
        <Wrapped {...props} />
      </main>
    </section>
    <img src={morningStar} alt="MorningStar" />
  </div>
);

export default LayoutAuth;
