import ACTION_TYPES from '../actions/actionTypes';

const stocksReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_MS_STOCKS_SUCCESS:
      return { ...state, stocks: action.payload.stocks };

    case ACTION_TYPES.GET_MS_STOCKS_FAILURE:
      return { ...state, error: action.payload.error };

    case ACTION_TYPES.SUBSCRIBE_TO_STOCK_ALERT_SUCCESS:
      return { ...state, stocksSubscription: [...state.stocksSubscription, ...action.payload.stocksSubscription], error: null };
      // return Object.assign(state, { stocksSubscription: action.payload.stocksSubscription });

    case ACTION_TYPES.SUBSCRIBE_TO_STOCK_ALERT_FAIL:
      return { ...state, error: action.payload.error };

    case ACTION_TYPES.GET_STOCK_SUBSCRIPTION_SUCCESS:
      return { ...state, stocksSubscription: action.payload.stocksSubscription, error: null };

    case ACTION_TYPES.GET_STOCK_SUBSCRIPTION_FAIL:
      return { ...state, error: action.payload.error };

    case ACTION_TYPES.REMOVE_STOCK_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        stocksSubscription: action.payload.stocksSubscription,
        error: null,
      };

    case ACTION_TYPES.REMOVE_STOCK_SUBSCRIPTION_FAIL:
      return { ...state, error: action.payload.error };

    default:
      return state;
  }
};

export default stocksReducer;
