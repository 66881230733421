/* eslint-disable import/prefer-default-export */
import qs from 'qs';
import axios from 'axios';
import { END_POINT } from '../constants';
import { requestApi } from './api';


const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

export const login = async (username, password, remember) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const body = {
    username,
    password,
    remember,
  };
  try {
    const res = await requestApi(`${API_DOMAIN}${END_POINT.LOGIN}`, 'post', qs.stringify(body), headers);
    // console.log('RESPONSE ', res);
    return Promise.resolve(res.data);
  } catch (err) {
    // console.log('ERROR ', err);
    return Promise.reject(err.response);
  }
};

export const resetPassword = async (email) => {
  const headers = {
    Accept: '*/*',
  };
  try {
    const res = await requestApi(`${API_DOMAIN}${END_POINT.RESET_PASSWORD}?email=${email}`, 'get', {}, headers);
    // console.log('RESPONSE ', res);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const changePassword = async (userId, password) => {
  try {
    const body = [{
      user_id: userId,
      password,
    }];
    const res = await axios.patch(`${API_DOMAIN}${END_POINT.USERS}`, body);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const signOut = async () => {
  try {
    const res = await axios.post(`${API_DOMAIN}${END_POINT.SIGNOUT}`, {});
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

/**
 * Get SSO (Get user is authenticated)
 */
export const getSSO = async () => {
  try {
    const res = await requestApi(`${API_DOMAIN}${END_POINT.SSO}`);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};
