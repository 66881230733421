import { combineReducers } from 'redux';
import ACTION_TYPES from '../actions/actionTypes';
import alertsReducer from './alertsReducer';
import stocksReducer from './stocksReducer';
import sectorsReducer from './sectorsReducer';

const rootReducer = (state = [], action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        targetUrl: null,
        role: null,
        isAuthenticated: false,
        authError: action.authError,
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        targetUrl: action.targetUrl,
        role: action.role,
        authError: null,
      };
    case ACTION_TYPES.RESET_FAILURE:
      return {
        ...state,
        resetError: action.resetError,
      };
    case ACTION_TYPES.RESET_SUCCESS:
      return {
        ...state,
        resetError: null,
      };
    case ACTION_TYPES.AUTH_SUCCESS:
      return { ...state, isAuthenticated: true, authError: null };
    case ACTION_TYPES.AUTH_FAILURE:
      return {
        ...state, isAuthenticated: false, token: null, targetUrl: null, authError: action.authError,
      };

    case ACTION_TYPES.PAGE_LOADING:
      return { ...state, isPageLoading: action.payload.isPageLoading };

    case ACTION_TYPES.AUTH_LOADING:
      return { ...state, isAuthLoading: action.payload.isAuthLoading };

    default:
      return state;
  }
};

export default combineReducers({
  rootReducer,
  alertsReducer,
  stocksReducer,
  sectorsReducer,
});
