import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PATH } from 'shared/constants';
import {
  Login, ForgotPassword, Homepage, MyAlerts, Stock,
} from './pages';

const Routes = () => (
  <Switch>
    <Route
      exact
      path={PATH.LOGIN}
      component={Login}
    />
    <Route
      path={PATH.CHANGE_PASSWORD}
      component={props => (<ForgotPassword {...props} isChangePassword />)}
    />
    <Route
      path={PATH.FORGOT_PASSWORD}
      component={ForgotPassword}
    />
    <Route
      path={PATH.ALERT}
      component={MyAlerts}
    />
    <Route
      path={`${PATH.STOCK}/:secId`}
      component={Stock}
    />
    <Route
      path={PATH.HOME}
      component={Homepage}
    />
  </Switch>
);

export default Routes;
