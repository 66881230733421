import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Input.scss';

// import { debounce } from 'lodash';

const Input = ({
  label, type, name, value, placeholder, disabled, required, isValid, onInputChange, customClass,
  isSmall, readOnly, onFocus,
}) => {
  const inputClasses = classNames({
    'input-group': true,
    'input-group--number': type === 'number',
    'input-group--disabled': disabled,
    'input-group--error': !isValid,
    'input-group--small': isSmall,
  });
  const [inputValue, setInputValue] = useState(value);

  const setValue = (e) => {
    const newVal = e.target.value;
    setInputValue(newVal);
    onInputChange(e);
  };

  return (
    <section className={inputClasses}>
      {label && (<span className="input-group__label">{label}</span>)}
      <div className="input-group__input">
        <input
          className={customClass}
          type={type}
          name={name}
          value={inputValue}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          onChange={setValue}
          readOnly={readOnly}
          onFocus={onFocus}
        />
      </div>
    </section>
  );
};

Input.propTypes = {
  /** Input Label */
  label: PropTypes.string,
  /** input type */
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'tel']),
  /** input name */
  name: PropTypes.string,
  /** input value */
  value: PropTypes.string,
  /** input placeholder */
  placeholder: PropTypes.string,
  /** input is disabled */
  disabled: PropTypes.bool,
  /** input is required */
  required: PropTypes.bool,
  /** Is there an error */
  isValid: PropTypes.bool,
  /** on input change */
  onInputChange: PropTypes.func,
  /** is input small */
  isSmall: PropTypes.bool,
  /** Addition class name */
  customClass: PropTypes.string,
  /** Is read only */
  readOnly: PropTypes.bool,
};

Input.defaultProps = {
  label: 'label here',
  type: 'text',
  name: '',
  value: '',
  placeholder: 'Placeholder here',
  disabled: false,
  required: false,
  isValid: true,
  isSmall: false,
  onInputChange: () => {},
  customClass: '',
  readOnly: false,
};

export default Input;
