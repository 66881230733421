import { STORAGE_KEY } from 'shared/constants';

// const convertArrToObj

/** Ger user Email in localstorage */
const getUserEmailOnLocalStorage = () => {
  const userInfo = JSON.parse(localStorage.getItem(STORAGE_KEY.USER_INFO));
  if (!userInfo || !userInfo.username) return null;
  return userInfo.username;
};

/** Get user Role in localstorage */
const getUserRoleOnLocalStorage = () => {
  const userInfo = JSON.parse(localStorage.getItem(STORAGE_KEY.USER_INFO));
  if (!userInfo || !userInfo.userRole || !Array.isArray(userInfo.userRole)) return null;
  return userInfo.userRole[0];
};

const utils = {
  getUserEmailOnLocalStorage,
  getUserRoleOnLocalStorage,
};

export default utils;
