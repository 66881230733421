import React, { Component } from 'react';
import track from 'react-tracking';
// import _ from 'lodash';
// import qs from 'qs';
import { connect } from 'react-redux';
import {
  getIsAuthenticated,
} from 'shared/redux/actions';
import { PATH } from 'shared/constants';
import { Spin } from 'components';
import './Stock.scss';
import stockAction from 'shared/redux/actions/stocks';
// import trackingEmailClickedSD from './Stock.tracking';

const pageUrl = process.env.REACT_APP_MS_DETAIL_PAGE;

class Stock extends Component {
  componentDidMount() {
    const {
      onGetUserSSO, token, history,
      // tracking, onFetchStocksByRole, location,
    } = this.props;
    const secId = window.location.pathname.split('/')[2];
    const encodedToken = encodeURIComponent(token);

    // if (isUserAuthenticated && token && secId) {
    //   window.location.assign(`${pageUrl}id=${secId}&token=${encodedToken}`);
    // //   console.log(`${pageUrl}id=${secId}&token=${encodedToken}`);
    // } else {
    //   /** redirect to login */
    //   console.log('redirect to login from Stock');
    //   history.push({
    //     pathname: PATH.LOGIN,
    //     state: {
    //       fromStock: true,
    //       secId,
    //     },
    //   });
    // }

    /** redirect user if not authenticated */
    onGetUserSSO()
      .then(() => {
        // if (location && location.search && location.search.includes('?origin=email&id')) {
        //   onFetchStocksByRole().then((stock) => {
        //     const selectedStock = _.find(stock, item => item.secId === secId);
        //     const body = {
        //       secId,
        //       ticker: selectedStock.ticker,
        //       name: selectedStock.name,
        //       id: qs.parse(location.search, { ignoreQueryPrefix: true }).id, // unique id for each clicked
        //     };
        //     if (body.secId && body.ticker && body.name) {
        //       trackingEmailClickedSD(tracking, body, window.location.assign(`${pageUrl}id=${secId}&token=${encodedToken}`));
        //     }
        //   });
        // } else {
        // }
        window.location.assign(`${pageUrl}id=${secId}&token=${encodedToken}`);
      })
      .catch(() => {
        history.push({
          pathname: PATH.LOGIN,
          state: {
            fromStock: true,
            secId,
          },
        });
      });
  }

  render() {
    return (
      <div className="db-home-page">
        <Spin />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.rootReducer.token,
  isUserAuthenticated: state.rootReducer.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  onGetUserSSO: () => dispatch(getIsAuthenticated()),
  onFetchStocksByRole: () => dispatch(stockAction.fetchStocksByRole()),
});

export default track({
  Page: 'Stock',
})(connect(mapStateToProps, mapDispatchToProps)(Stock));
