import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SubHeader.scss';

const SubHeader = ({
  title,
  headingLevel,
  description,
}) => {
  const validHeadingLevels = ['h1', 'h2', 'h3', 'h4'];
  const safeHeading = headingLevel ? headingLevel.toLowerCase() : '';
  const Title = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';
  const classes = classNames({
    'nw-sub-header': true,
  });
  return (
    <div className={classes}>
      <Title>{title}</Title>
      {description && <p>{description}</p>}
    </div>
  );
};

SubHeader.propTypes = {
  /** title */
  title: PropTypes.string,
  /** heading level */
  headingLevel: PropTypes.string,
  /** description */
  description: PropTypes.string,
};

SubHeader.defaultProps = {
  title: null,
  headingLevel: 'h1',
  description: null,
};

export default SubHeader;
