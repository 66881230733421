/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './StockList.scss';
import CheckMark from 'components/CheckMark';
import { Button } from 'components';

const StockHeader = ({
  columns, sort, onSelectedStockRemove, showRemoveBtn, isSelectAll, onSelectAll,
}) => {
  const classes = classNames({
    'nw-stock-header': true,
  });

  return (
    <div className={classes}>
      <div className="nw-stock-header__col">
        <CheckMark id={`rows-${columns.length}`} isChecked={isSelectAll} onCheckMarkChange={onSelectAll} type="checkbox" />
      </div>
      {columns.map((column, columnIndex) => (
        <div
          onClick={() => { sort({ field: column.field, sort: column.sort === 'asc' ? 'desc' : 'asc' }); }}
          className={`nw-stock-header__col ${column.sort}`}
          key={columnIndex}
          style={{ width: [column.width] }}
        >
          {column.label}
          <span />
        </div>
      ))}
      <div className="nw-stock-header__col nw-stock--action">
        {showRemoveBtn && <Button className="nw-stock--action__desktopDelete" onButtonClick={onSelectedStockRemove} value="Delete selected stock(s)" isSmall isSecondary isRounded />}
      </div>
    </div>
  );
};

StockHeader.propTypes = {
  // on sorting
  sort: PropTypes.func,
  /** remove stocks function */
  onSelectedStockRemove: PropTypes.func,
  /** show delete button */
  showRemoveBtn: PropTypes.bool,
  /** Select All func */
  onSelectAll: PropTypes.func,
  /** is select all */
  isSelectAll: PropTypes.bool,
};

StockHeader.defaultProps = {
  sort: () => {},
  onSelectedStockRemove: () => {},
  showRemoveBtn: false,
  onSelectAll: () => {},
  isSelectAll: false,
};

export default StockHeader;
