export default {
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  RESET_FAILURE: 'RESET_FAILURE',
  RESET_SUCCESS: 'RESET_SUCCESS',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_FAILURE: 'AUTH_FAILURE',

  TOGGLE_ALERT_GENERAL_SETTING: 'TOGGLE_ALERT_GENERAL_SETTING',
  TOGGLE_ALERT_DAILY_BRIEFING: 'TOGGLE_ALERT_DAILY_BRIEFING',

  SAVE_MORNING_START_RATING: 'SAVE_MORNING_START_RATING',
  SAVE_RESEARCH_PUBLICATION: 'SAVE_RESEARCH_PUBLICATION',
  SAVE_PUBLICATION_REPORTS: 'SAVE_PUBLICATION_REPORTS',
  SAVE_PUBLICATION_NOTES: 'SAVE_PUBLICATION_NOTES',
  SAVE_PUBLICATION_FAIR_VALUE: 'SAVE_PUBLICATION_FAIR_VALUE',
  SAVE_PUBLICATION_UNCERTAINTY: 'SAVE_PUBLICATION_UNCERTAINTY',
  SAVE_PUBLICATION_ECONOMIC_MOAT: 'SAVE_PUBLICATION_ECONOMIC_MOAT',

  SET_FIRST_ALERT_SETTINGS: 'SET_FIRST_ALERT_SETTINGS',
  ALERT_SETTINGS_SAVE: 'ALERT_SETTINGS_SAVE',
  ALERT_SETTINGS_SUBMISSION_SUCCESS: 'ALERT_SETTINGS_SUBMISSION_SUCCESS',
  ALERT_SETTINGS_SUBMISSION_FAILURE: 'ALERT_SETTINGS_SUBMISSION_FAILURE',
  ALERT_SETTINGS_GET_SUCCESS: 'ALERT_SETTINGS_GET_SUCCESS',
  ALERT_SETTINGS_GET_FAILURE: 'ALERT_SETTINGS_GET_FAILURE',

  // SAVE_ARR_OF_STOCKS_WATCHING: 'SAVE_ARRAY_OF_STOCKS_WATCHING',
  SUBSCRIBE_TO_STOCK_ALERT_SUCCESS: 'SUBSCRIBE_TO_STOCK_ALERT_SUCCESS',
  SUBSCRIBE_TO_STOCK_ALERT_FAIL: 'SUBSCRIBE_TO_STOCK_ALERT_FAIL',
  GET_STOCK_SUBSCRIPTION_SUCCESS: 'GET_STOCK_SUBSCRIPTION_SUCCESS',
  GET_STOCK_SUBSCRIPTION_FAIL: 'GET_STOCK_SUBSCRIPTION_FAIL',
  REMOVE_STOCK_SUBSCRIPTION_SUCCESS: 'REMOVE_STOCK_SUBSCRIPTION_SUCCESS',
  REMOVE_STOCK_SUBSCRIPTION_FAIL: 'REMOVE_STOCK_SUBSCRIPTION_FAIL',

  GET_MS_STOCKS_SUCCESS: 'GET_MS_STOCKS_SUCCESS',
  GET_MS_STOCKS_FAILURE: 'GET_MS_STOCKS_FAILURE',
  GET_MS_SECTORS_SUCCESS: 'GET_MS_SECTORS_SUCCESS',
  GET_MS_SECTORS_FAILURE: 'GET_MS_SECTORS_FAILURE',
  SELECT_A_MS_SECTOR: 'SELECT_A_MS_SECTOR',
  SELECT_ALL_MS_SECTOR: 'SELECT_ALL_MS_SECTOR',

  AUTH_LOADING: 'AUTH_LOADING',
  PAGE_LOADING: 'PAGE_LOADING',
  ALERT_SETTING_LOADING: 'ALERT_SETTING_LOADING',
};
