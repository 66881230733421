import axios from 'axios';
import { END_POINT } from 'shared/constants';
import utils from 'shared/helper/utils';

const APP_TRACKING = process.env.REACT_APP_TRACKING_API_DOMAIN;
const IPDATA_KEY = process.env.REACT_APP_IPDATA_KEY;
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
const APP_DOMAIN = process.env.REACT_APP_DOMAIN;

export const requestApi = (url, method = 'get', params = '', headers = '') => axios({
  method,
  url,
  data: params,
  headers,
  withCredentials: true,
});


export const trackingService = async (body) => {
  try {
    const res = await axios.post(APP_TRACKING, body);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const countryDetector = async () => {
  try {
    const res = await axios.get(`https://api.ipdata.co/?api-key=${IPDATA_KEY}`);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const autoLogin = async () => {
  try {
    const res = await requestApi(`${API_DOMAIN}/auto-login?origin=${APP_DOMAIN}`);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

/**
 * Get stocks by role
 * @param {*} role
 */
export const getStocksByRole = async (role) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  // console.log('local store', utils.getUserRoleOnLocalStorage());
  try {
    const res = await requestApi(`${APP_DOMAIN}${END_POINT.SMART_ALERT_API}/api/v2/stocks?role=${role}`, 'get', {}, headers);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

/**
 * Get sectors by role
 * @param {*} role
 */
export const getSectorsByRole = async (role) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  try {
    const res = await requestApi(`${APP_DOMAIN}${END_POINT.SMART_ALERT_API}/api/v2/sectors?role=${role}`, 'get', {}, headers);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

/**
 * Subscribe Stock / save settings as well (option)
 * @param {obj.stocks, obj.settings} obj
 */
export const postSubscribeToStock = async (arrOfObj) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const generalData = {
    applyToAllSettings: true,
    email: utils.getUserEmailOnLocalStorage(),
  };
  // const newBody = obj && obj.settings ? Object.assign(generalData, { settings: obj.settings, stocks: obj.stocks }) : Object.assign(generalData, { stocks: obj.stocks });
  const newBody = Object.assign(generalData, { stocks: arrOfObj });
  try {
    const res = await requestApi(`${APP_DOMAIN}${END_POINT.SMART_CLIENT_DATA_API}/api/v2/settings/stocks`, 'post', newBody, headers);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

/**
 * Get stock subscriptions
 */
export const getSubscribedStocks = async () => {
  const userEmail = utils.getUserEmailOnLocalStorage();
  try {
    const res = await requestApi(`${APP_DOMAIN}${END_POINT.SMART_CLIENT_DATA_API}/api/v2/settings/stocks?email=${userEmail}`);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

/**
 * Remove stocks onn subscription
 * @param {*} arrOfStrings
 */
export const deleteSubscribedStocks = async (arrOfStrings) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const userEmail = utils.getUserEmailOnLocalStorage();
  const body = {
    email: userEmail,
    secIds: arrOfStrings,
  };
  try {
    const res = await requestApi(`${APP_DOMAIN}${END_POINT.SMART_CLIENT_DATA_API}/api/v2/settings/stocks`, 'delete', body, headers);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

/**
 * Save User Settings
 * @param {*} body
 */
export const postSettings = async (arrOfObj) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const generalData = {
    // applyToAllStocks: true,
    overwrite: false,
    userEmail: utils.getUserEmailOnLocalStorage(),
  };
  const newBody = Object.assign(generalData, { settings: arrOfObj });
  try {
    const res = await requestApi(`${APP_DOMAIN}${END_POINT.SMART_CLIENT_DATA_API}/api/v2/settings`, 'post', newBody, headers);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

/**
 * Get User Settings
 */
export const getSettings = async () => {
  const userEmail = utils.getUserEmailOnLocalStorage();
  try {
    const res = await requestApi(`${APP_DOMAIN}${END_POINT.SMART_CLIENT_DATA_API}/api/v2/settings?email=${userEmail}`);
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};
