import {
  PATH,
  STORAGE_KEY,
} from 'shared/constants';
import store from 'shared/redux/store';
// import Cookies from 'js-cookie';
import { countryDetector, autoLogin } from 'shared/services/api';
import { loginSuccess } from 'shared/redux/actions';
import { isMobile, browserName } from 'react-device-detect';
import {
  trackBrowser, trackDevice, trackCountry, trackRoles, trackDBOffice,
} from './App.tracking';


export const onTrackApp = async (tracking, callback) => {
  try {
    const res = await countryDetector();
    await Promise.all([
    // tracking browser
      await trackBrowser(tracking, browserName),
      // tracking device
      await trackDevice(tracking, isMobile ? 'mobile' : 'desktop'),
      // tracking country of origin
      await trackCountry(tracking, res),
    ]).then(() => {
      const userInfo = JSON.parse(localStorage.getItem(STORAGE_KEY.USER_INFO));
      if (userInfo && userInfo.userRole) {
        const { userRole, username, dbOffice } = userInfo;
        if (dbOffice && dbOffice !== null && dbOffice !== '') {
        // track DB Office
          trackDBOffice(tracking, { username, dbOffice });
        }
        // track user per day with roles
        trackRoles(tracking, { username, roles: userRole }, callback);
      } else {
      // debugger;
        callback();
      }
    });
  } catch (error) {
    callback();
  }
};

/**
 * Get data from response and go to MS page
 * TODO: need to imrove in the case error
 * @param {response} res
 */
export const gotoMSPage = (token, targetUrl) => {
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', targetUrl);
  const hiddenField = document.createElement('input'); hiddenField.setAttribute('type', 'hidden');
  hiddenField.setAttribute('name', 'token');
  hiddenField.setAttribute('value', token);
  form.appendChild(hiddenField);
  document.body.appendChild(form);
  form.submit();
};

// localhost:3000/stock/0P00009S8U
const isUserLogin = (history, tracking) => {
  // try auto login
  autoLogin()
  // disable the auto login
    .then((res) => {
      store.dispatch(loginSuccess(res.token, res.targetUrl, res.roles[0]));
      // store.dispatch(getIsAuthenticated());
      // if (window.location.pathname !== PATH.ALERT && !window.location.pathname.split('/')[2]) {
      //   onTrackApp(tracking, () => gotoMSPage(res.token, res.targetUrl));
      // }
      const secId = window.location.pathname.split('/')[2];
      if (window.location.href.indexOf(PATH.ALERT) > -1) {
        // console.log(window.location.pathname !== PATH.ALERT && !window.location.pathname.split('/')[2]);
        if (window.location.pathname.split('/')[2]) {
          history.push(`${PATH.ALERT}/${secId}`);
        } else {
          onTrackApp(tracking, () => history.push(PATH.ALERT));
        }
      } else if (window.location.href.indexOf(PATH.STOCK) > -1) {
        if (window.location.pathname.split('/')[2]) {
          history.push(`${PATH.STOCK}/${secId}`);
        } else {
          onTrackApp(tracking, () => history.push(PATH.STOCK));
        }
      } else {
        onTrackApp(tracking, () => gotoMSPage(res.token, res.targetUrl));
      }
    })
    .catch(() => {
      // const { token, isAuthenticated, targetUrl } = store.getState().rootReducer;
      const secId = window.location.pathname.split('/')[2];
      if (window.location.href.indexOf(PATH.ALERT) > -1) {
        if (window.location.pathname.split('/')[2]) {
          history.push(`${PATH.ALERT}/${secId}`);
        } else {
          onTrackApp(tracking, () => history.push(PATH.ALERT));
        }
      } else if (window.location.href.indexOf(PATH.STOCK) > -1) {
        if (window.location.pathname.split('/')[2]) {
          history.push(`${PATH.STOCK}/${secId}`);
        } else {
          onTrackApp(tracking, () => history.push(PATH.STOCK));
        }
      } else {
        const prevLocation = history.location.state;
        console.log('LOCATION ', prevLocation);
        if (prevLocation && prevLocation.fromMyAlerts) {
          onTrackApp(tracking, () => {
            history.push({
              pathname: PATH.LOGIN,
              state: {
                fromMyAlerts: true,
              },
            });
          });
        } else if (prevLocation && prevLocation.fromStock) {
          onTrackApp(tracking, () => {
            history.push({
              pathname: PATH.LOGIN,
              state: {
                fromStock: true,
                secId,
              },
            });
          });
        } else {
          console.log('FIRED!!!');
          onTrackApp(tracking, () => history.push(PATH.LOGIN));
        }
      }
    });
};

const Utils = {
  isUserLogin,
  onTrackApp,
  gotoMSPage,
};

export default Utils;
