import React from 'react';
import './LayoutWithNavSidebar.scss';
import Navbar from './Navbar';

const LayoutWithNavSidebar = (Content, Sidebar) => props => (
  <div className="db-hoc-page">
    <Navbar />
    <div className="db-hoc-main">
      <div className="db-hoc-content">
        <Content {...props} />
      </div>
      <div className="db-hoc-sidebar">
        <Sidebar {...props} />
      </div>
    </div>
  </div>
);

export default LayoutWithNavSidebar;
