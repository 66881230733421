/* eslint-disable import/prefer-default-export */
import {
  login, resetPassword, changePassword, signOut, getSSO,
} from 'shared/services/auth';
import ACTION_TYPES from './actionTypes';

// export const loginUser = async (username, password) => login(username, password);
const authSuccess = () => ({
  type: ACTION_TYPES.AUTH_SUCCESS,
});

export const authFail = authError => ({
  type: ACTION_TYPES.AUTH_FAILURE,
  authError,
});

const authLoading = isAuthLoading => ({
  type: ACTION_TYPES.AUTH_LOADING,
  payload: { isAuthLoading },
});

export const getIsAuthenticated = () => dispatch => new Promise((resolve, reject) => {
  dispatch(authLoading(true));
  getSSO().then(() => {
    dispatch(authSuccess());
    dispatch(authLoading(false));
    resolve();
  }).catch((err) => {
    dispatch(authFail(err));
    dispatch(authLoading(false));
    reject();
  });
});

export const loginSuccess = (token, targetUrl, userRole) => ({
  type: ACTION_TYPES.LOGIN_SUCCESS,
  token,
  targetUrl,
  role: userRole,
});

export const loginUser = (username, password, remember) => dispatch => new Promise((resolve, reject) => {
  login(username, password, remember)
    .then((res) => {
      // console.log('resssss ', res);
      // dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, token: res.token, targetUrl: res.targetUrl });
      dispatch(loginSuccess(res.token, res.targetUrl, res.roles[0]));
      // dispatch(getIsAuthenticated());
      resolve(res);
    }).catch((err) => {
    //   console.log('login fail', err);
      dispatch({ type: ACTION_TYPES.LOGIN_FAILURE, authError: err });
      reject(err);
    });
});


export const resetUserPassword = email => dispatch => new Promise((resolve, reject) => {
  resetPassword(email)
    .then((res) => {
      //   console.log('login success', res);
      dispatch({ type: ACTION_TYPES.RESET_SUCCESS });
      resolve(res);
    }).catch((err) => {
      //   console.log('login fail', err);
      dispatch({ type: ACTION_TYPES.RESET_FAILURE, resetError: err });
      reject(err);
    });
});

export const changeUserPassword = (userId, password) => new Promise((resolve, reject) => {
  changePassword(userId, password)
    .then((res) => {
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    });
});

export const signUserOut = () => new Promise((resolve, reject) => {
  signOut().then((res) => {
    resolve(res);
  })
    .catch((err) => {
      reject(err);
    });
});
