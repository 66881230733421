import React, { Component } from 'react';
import {
  Modal,
  // Widget, AlertSettings
} from 'components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { alerts } from 'shared/redux/actions';
import { getStockBySecId } from 'shared/redux/selectors';

class AddAlertPopup extends Component {
  // state = {
  //   isLoading: true,
  // }

  static propTypes = {
    /** show */
    show: PropTypes.bool,
    /** handleClose */
    handleClose: PropTypes.func,
    /** handleSubmit */
    handleSubmit: PropTypes.func,
    /** is modal small */
    isModalSmall: PropTypes.bool,
  };

  static defaultProps = {
    show: false,
    handleClose: () => {},
    handleSubmit: () => {},
    isModalSmall: true,
  };

  componentDidMount() {
    // const { alertSettings, isAlertSettingsSetup } = this.props;
    // if (alertSettings !== undefined && isAlertSettingsSetup !== undefined) {
    //   setTimeout(() => {
    //     this.setState({ isLoading: false });
    //   }, 500);
    // }
  }


  onHandleSubmit = () => {
    const {
      handleSubmit,
      stockDetails,
    } = this.props;

    handleSubmit(stockDetails);
    this.props.history.push('/my-alerts');
  };

  isStockonSubscription = () => {
    const secId = window.location.pathname.split('/')[2];
    const subscription = this.props.addedList;
    if (!secId && !subscription) return false;
    return subscription.includes(secId);
  }

  render() {
    const {
      show, handleClose, isModalSmall,
      // alertSettings, onSaveAlertSettings,
      isAlertSettingsSetup, stockDetails, isAlertLoading,
    } = this.props;
    // console.log(stockDetails);
    return (
      <Modal
        show={show}
        subtitle="Add email alerts on this stock"
        title={stockDetails && stockDetails.name}
        tickerCode={stockDetails && stockDetails.ticker}
        isSmall={isModalSmall}
        handleClose={handleClose}
        handleSubmit={this.onHandleSubmit}
        hideCloseButton={this.isStockonSubscription()}
      >
        {!isAlertLoading ? (
          <>
            {/* {!isAlertSettingsSetup && !this.isStockonSubscription() && (
            <Widget title="I want to be automatically notified when there is" headingLevel="h3">
              <AlertSettings />
            </Widget>
            )} */}

            {isAlertSettingsSetup && this.isStockonSubscription() && (
            <span className="notification notification--error">This stock is already in your watchlist!</span>
            )}
          </>
        ) : (<span>Loading...</span>)}

      </Modal>

    );
  }
}

const mapStateToProps = (state) => {
  const secId = window.location.pathname.split('/')[2];
  return {
    alertSettings: state.alertsReducer.alertSettings,
    isAlertLoading: state.alertsReducer.isAlertLoading,
    // isAlertSettingsSetup: state.alertsReducer.isAlertSettingSetup,
    stockDetails: getStockBySecId(secId, state),
  };
};

export default connect(mapStateToProps, null)(AddAlertPopup);
