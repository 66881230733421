import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { alerts } from 'shared/redux/actions';
// import { debounce } from 'lodash';
import './AlertSettings.scss';
import { CheckMark, Switch } from 'components';

class AlertSettings extends Component {
  /**
   * This is to submit data only when the user is done personalising the form
   */
  // saveChangesWhenUserStoppedModifying = debounce(
  //   () => {
  //     this.props.onSubmit(this.state);
  //   },
  //   500,
  // );

  static propTypes = {
    /** form name */
    formName: PropTypes.string,
    /** Daily briefing setting */
    isReceiveDailyBriefing: PropTypes.bool,
    /** Morning star rating */
    isMorningStarRatingActive: PropTypes.bool,
    /** Research Publication active */
    isResearchPublicationActive: PropTypes.bool,
    /** is Reports */
    isReportsActive: PropTypes.bool,
    /** is Notes active */
    isNotesActive: PropTypes.bool,
    /** is Fair value active */
    isFairValueActive: PropTypes.bool,
    /** is uncertainty active */
    isUncertaintyActive: PropTypes.bool,
    /** is economic moat active */
    isEconomicMoatActive: PropTypes.bool,
    /** submit states */
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    formName: 'alert-settings',
    isReceiveDailyBriefing: true,
    isMorningStarRatingActive: true,
    isResearchPublicationActive: false,
    isReportsActive: true,
    isNotesActive: true,
    isFairValueActive: true,
    isUncertaintyActive: true,
    isEconomicMoatActive: true,
    onSubmit: () => {},
  };

  // state = {
  //   isMorningStarRatingActive: this.props.isMorningStarRatingActive,
  //   isResearchPublicationActive: this.props.isResearchPublicationActive,
  //   isReportsActive: this.props.isReportsActive,
  //   isNotesActive: this.props.isNotesActive,
  //   isFairValueActive: this.props.isFairValueActive,
  //   isUncertaintyActive: this.props.isUncertaintyActive,
  //   isEconomicMoatActive: this.props.isEconomicMoatActive,
  // };

  /**
   * on input change, update the state based on element's name attribue
   */
  onElementChange = (evt) => {
    // evt.preventDefault();
    // this.setState({
    //   [evt.target.name]: !!evt.target.checked,
    // }, () => {
    //   this.saveChangesWhenUserStoppedModifying();
    // });
    this.props.onSubmit(evt);
  }

  onHandleTogglePublications = (e) => {
    const {
      isReportsActive, isNotesActive, onTogglePublicationReports, onTogglePublicationNotes,
    } = this.props;
    const inputName = e.target.name;

    if (inputName === 'isReportsActive') {
      if (isReportsActive && !isNotesActive) return;
      onTogglePublicationReports(!isReportsActive);
    }

    if (inputName === 'isNotesActive') {
      if (isNotesActive && !isReportsActive) return;
      onTogglePublicationNotes(!isNotesActive);
    }
  }

  render() {
    const {
      isMorningStarRatingActive, isResearchPublicationActive, isReportsActive, isNotesActive, isFairValueActive, isUncertaintyActive, isEconomicMoatActive,
      onToggleMSRating, onToggleResearchPublication,
      // onTogglePublicationReports, onTogglePublicationNotes,
      onTogglePublicationFairValue,
      onTogglePublicationUncertainty, onTogglePublicationEconomicMoat,
    } = this.props;
    const {
      formName,
    } = this.props;
    // console.log('+++++ ', this.props);
    const classes = classNames({
      'nw-alert-settings': true,
    });
    return (
      <form className={classes}>
        <div className="nw-alert-settings__item">
          <Switch id={`${formName}-morningStarRating`} name="isMorningStarRatingActive" label="A change of Morningstar Rating" isChecked={isMorningStarRatingActive} onSwitchChange={onToggleMSRating} />
        </div>
        <div className="nw-alert-settings__item">
          <div className="mb-4">
            <Switch id={`${formName}-researchPublication`} name="isResearchPublicationActive" label="A new research publication" isChecked={isResearchPublicationActive} onSwitchChange={onToggleResearchPublication} />
          </div>
          {isResearchPublicationActive && (
            <div className="pl-6">
              <div>
                <CheckMark id={`${formName}-reports`} name="isReportsActive" type="checkbox" hasContainer label="Reports" isChecked={isReportsActive} onCheckMarkChange={e => this.onHandleTogglePublications(e)} />
                <CheckMark id={`${formName}-notes`} name="isNotesActive" type="checkbox" hasContainer label="Notes" isChecked={isNotesActive} onCheckMarkChange={e => this.onHandleTogglePublications(e)} />
              </div>
              <div className="font-size-small mb-3">
                <span>Personalise more by filtering alerts.&nbsp;</span>
                <strong>
                  Notify me &nbsp;
                  <u>only</u>
                  :
                </strong>
              </div>
              <div>
                {
                  (isReportsActive || isNotesActive) && (
                    <CheckMark
                      id={`${formName}-fairValue`}
                      name="isFairValueActive"
                      type="checkbox"
                      label={(
                        <span>
                          If there is a
                          {' '}
                          <strong>Fair Value</strong>
                          {' '}
                          change
                        </span>
                      )}
                      isChecked={isFairValueActive}
                      onCheckMarkChange={onTogglePublicationFairValue}
                    />
                  )
                }
                {
                  isReportsActive && (
                    <CheckMark
                      id={`${formName}-uncertainty`}
                      name="isUncertaintyActive"
                      type="checkbox"
                      label={(
                        <span>
                          If there is an
                          {' '}
                          <strong>Uncertainty</strong>
                          {' '}
                          change
                        </span>
                      )}
                      isChecked={isUncertaintyActive}
                      onCheckMarkChange={onTogglePublicationUncertainty}
                    />
                  )
                }
                {
                  isReportsActive && (
                    <CheckMark
                      id={`${formName}-economicMoat`}
                      name="isEconomicMoatActive"
                      type="checkbox"
                      label={(
                        <span>
                          If there is an
                          {' '}
                          <strong>Economic Moat</strong>
                          {' '}
                          change
                        </span>
                      )}
                      isChecked={isEconomicMoatActive}
                      onCheckMarkChange={onTogglePublicationEconomicMoat}
                    />
                  )
                }
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  isMorningStarRatingActive: state.alertsReducer.alertSettings.isMorningStarRatingActive,
  isResearchPublicationActive: state.alertsReducer.alertSettings.isResearchPublicationActive,
  isReportsActive: state.alertsReducer.alertSettings.isReportsActive,
  isNotesActive: state.alertsReducer.alertSettings.isNotesActive,
  isFairValueActive: state.alertsReducer.alertSettings.isFairValueActive,
  isUncertaintyActive: state.alertsReducer.alertSettings.isUncertaintyActive,
  isEconomicMoatActive: state.alertsReducer.alertSettings.isEconomicMoatActive,
});

const mapDispatchToProps = dispatch => ({
  onToggleMSRating: () => dispatch(alerts.toggleMorningStarRating()),
  onToggleResearchPublication: () => dispatch(alerts.toggleResearchPublication()),
  onTogglePublicationReports: isReportsActive => dispatch(alerts.togglePublicationReports(isReportsActive)),
  onTogglePublicationNotes: isNotesActive => dispatch(alerts.togglePublicationNotes(isNotesActive)),
  onTogglePublicationFairValue: () => dispatch(alerts.togglePublicationFairValue()),
  onTogglePublicationUncertainty: () => dispatch(alerts.togglePublicationUncertainty()),
  onTogglePublicationEconomicMoat: () => dispatch(alerts.togglePublicationEconomicMoat()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AlertSettings);
