/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Cookies from 'js-cookie';
import _ from 'lodash';
// import qs from 'qs';
import './MyAlerts.scss';
import {
  Button, StockList, SubHeader, SearchInput, Spin, PageLoader,
} from 'components';
import {
  stocks, alerts, getIsAuthenticated, authFail, sectorsAction,
} from 'shared/redux/actions';
import { TRACKING_KEY, PATH } from 'shared/constants';
import {
  getStocks, getIsAlertSettingSetup, getUserSubscription,
  getStockSubscriptionIds, getShowWelcome, getSelectedSector,
  // getStocksWithSubscription,
} from 'shared/redux/selectors';
import track from 'react-tracking';
import LayoutWithNavSidebar from 'layouts/LayoutWithNavSidebar';
import {
  AddAlertPopup, AddSectorPopup,
  WelcomePopup,
} from 'popups';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from './Sidebar';
import 'react-toastify/dist/ReactToastify.css';
import {
  // trackingEmailClickedMA,
  trackVisitAlert, trackAddStock, trackSearchStock,
} from './MyAlerts.tracking';


// import { onTrackApp, gotoMSPage } from '../../App.utils';

class Alert extends Component {
  state = {
    sectorPopupShow: false,
    welcomePopupShow: this.props.showWelcomePopup,
  }

  componentDidMount() {
    const {
      // onGetStockSubcription,
      history,
      onGetUserSSO,
      onGetSavedSettings,
      tracking,
      isAuth,
      onGetStocks,
      onGetSubscribedStocks,
      onGetSectors,
    } = this.props;

    /** Check my alert access origin */
    // this.checkAccessOrigin();

    /** redirect user if not authenticated */
    onGetUserSSO().catch(() => {
      history.push({
        pathname: PATH.LOGIN,
        state: {
          fromMyAlerts: true,
        },
      });
    });

    if (!isAuth.token) {
      history.push({
        pathname: PATH.LOGIN,
        state: {
          fromMyAlerts: true,
        },
      });
    } else {
      onGetStocks();
      onGetSubscribedStocks().then((res) => {
        onGetSectors(res);
      });
    }

    onGetSavedSettings(); // GET SAVED ALERT SETTINGS
    // onGetStockSubcription(); // GET SAVED STOCKS SUBSCRIPTION

    /** */
    trackVisitAlert(tracking);
    /** */

    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        this.setState({
          sectorPopupShow: false,
        });
      }
    });
  }

  // checkAccessOrigin = () => {
  //   const { history: { location }, tracking } = this.props;
  //   if (location && location.search && location.search.includes('?origin=email&id')) {
  //     const { id } = qs.parse(location.search, { ignoreQueryPrefix: true });
  //     trackingEmailClickedMA(tracking, id);
  //   }
  // }

  hideAlertPopup = () => {
    /** to close alert popup, just remove the secId parameter */
    this.props.history.push('/my-alerts');
  }

  /** Check if stock is already in the watchlist */
  isStockonSubscription = (secId) => {
    const subscription = this.props.stocksSubscriptionIds;
    if (!secId && !subscription) return false;
    return subscription.includes(secId);
  };

  /**
   * Handle the add alert on add alert "POPUP"
   * OR this can be moved on the Alert Popup itself
   * */
  handleAddAlertFromPopup = (obj) => {
    const {
      isAlertSettingsSetup, tracking,
      // onSetupFirstAlertSettings, onSubmitAlertSettings,
    } = this.props;

    /** save alert settings + subscribe to stock alert */
    // if (!isAlertSettingsSetup) {
    //   onSetupFirstAlertSettings();
    //   onSubmitAlertSettings()
    //     .then(() => {
    //       this.props.onSubscribeToStock([obj]);
    //     });
    // }

    /** if alert settings is already setup, just send stock alert subscription */
    if (isAlertSettingsSetup) {
      if (!this.isStockonSubscription(obj.secId)) {
        this.props.onSubscribeToStock([obj]).then(() => {
          trackAddStock(tracking, obj.name, TRACKING_KEY.ADD_STOCK_FROM_MS_TOOL);
        });
      }
    }

    /** If no error in subscription, notify user it's successful */
    if (!this.props.subscriptionError && !this.isStockonSubscription(obj.secId)) {
      toast.success(<SubHeader title="Successfully added" headingLevel="h4" description={obj.name} />);
    }
  }

  /** Handle the add alert on "Search Input" */
  handleAddAlert = (obj) => {
    const { isAlertSettingsSetup, onSubscribeToStock, tracking } = this.props;
    if (!isAlertSettingsSetup) {
      /** open add alert popup (first time user with no alert setting setup) */
      this.props.history.push(`/my-alerts/${obj.secId}`);
    } else {
      /** Submit stock to backend for monitoring */
      onSubscribeToStock([obj]).then(() => {
        trackAddStock(tracking, obj.name, TRACKING_KEY.ADD_STOCK_FROM_INPUT);
      });
      /** If no error in subscription, notify user it's successful */
      if (!this.props.subscriptionError) {
        toast.success(<SubHeader title="Successfully added" headingLevel="h4" description={obj.name} />);
      }
    }
  }

  showSectorPopup = () => {
    this.setState({ sectorPopupShow: true });
  }

  hideSectorPopup = () => {
    this.setState({ sectorPopupShow: false });
  }

  submitSectorPopup = () => {
    const { selectedSectors, onSubscribeToStock, tracking } = this.props;
    const description = selectedSectors.length === 1 ? `${_.head(selectedSectors).name}.` : `${selectedSectors.length} stocks.`;

    onSubscribeToStock(selectedSectors).then(() => {
      trackAddStock(tracking, description, TRACKING_KEY.ADD_STOCK_BY_SECTOR);

      toast.success(
        <SubHeader
          title="Successfully added"
          headingLevel="h4"
          description={description}
        />,
      );
    }).catch(() => {
      toast.error(<SubHeader title="Faild added" headingLevel="h4" description="" />);
    });
    this.setState({ sectorPopupShow: false });
  }

  render() {
    const columns = [
      {
        label: 'Stock Name',
        field: 'name',
        sort: 'asc',
        width: '45%',
        customClass: 'font-weight-bold',
        disabledClass: 'text-line-through',
      },
      {
        label: 'Ticker',
        field: 'ticker',
        sort: 'asc',
        width: '15%',
        customClass: 'color-darkGrey',
      },
    ];

    const {
      isAlertSettingsSetup, history, stockList, stocksSubscription, onRemoveSubscribedStock, stocksSubscriptionIds, selectedSectors, tracking, isUserAuthenticated, isAuth,
      isPageLoading, isAlertLoading, isAuthLoading,
      onSetupFirstAlertSettings, onSubmitAlertSettings,
    } = this.props;
    const secIdParam = window.location.pathname.split('/')[2];
    const findSecId = stockList.find(stock => stock.secId === secIdParam);
    const isSecIdValid = findSecId !== undefined;
    const noOfStocksWatching = stocksSubscriptionIds.length;
    // console.log(window.location.pathname.split('/')[1], window.location.pathname.split('/')[2]);
    return (
      <>
        {isAuthLoading && <PageLoader isLoading={isAuthLoading} />}
        {
          !isAuthLoading && isUserAuthenticated && isAuth.token ? (
            <div className="db-alert-page">

              <div className="nw-search-input-block">
                <SearchInput
                  hasCloseButton
                  isAlertSettingsSetup={isAlertSettingsSetup}
                  data={stockList}
                  addedList={stocksSubscriptionIds}
                  history={history}
                  onAddAlert={this.handleAddAlert}
                  trackSearchStock={type => trackSearchStock(tracking, type)}
                />
              </div>

              <div className="nw-widget-stock">
                <div className="nw-widget-stock__header">
                  <SubHeader
                    title={`${noOfStocksWatching && noOfStocksWatching !== 0 ? noOfStocksWatching : ''} Stocks I’m Monitoring`}
                    headingLevel="h2"
                    description="Notify me on when there are changes on the following:"
                  />
                  <Button isPrimary value="Add stock(s) by Sector" isRounded onButtonClick={this.showSectorPopup} />
                </div>

                {!isPageLoading && stocksSubscription ? (
                  <div className="nw-widget-stock__body">
                    <StockList
                      isPageLoading={isPageLoading}
                      columns={columns}
                      rows={stocksSubscription}
                      stocksSubscriptionIds={stocksSubscriptionIds}
                      onStockRemove={(e) => {
                        onRemoveSubscribedStock([e]);
                        toast.success(<SubHeader title="Successfully removed!" headingLevel="h4" />);
                      }}
                      onGroupStocksRemove={(e) => {
                        onRemoveSubscribedStock(e);
                        toast.success(<SubHeader title="Successfully removed!" headingLevel="h4" />);
                      }}
                    />
                  </div>
                ) : <div className="nw-widget-stock__body nw-widget-stock__body--loading"><Spin /></div>}
              </div>

              {!isAlertLoading && !isAlertSettingsSetup && Array.isArray(stocksSubscription) && stocksSubscription.length === 0 && (
              <WelcomePopup
                show={this.state.welcomePopupShow}
                onHandleOk={() => {
                  if (!isAlertSettingsSetup) {
                    onSetupFirstAlertSettings();
                    onSubmitAlertSettings();
                  }
                  this.setState({ welcomePopupShow: false });
                }}
              />
              )}
              {/* <WelcomePopup show={this.props.showWelcomePopup || this.state.welcomePopupShow} onHandleOk={() => this.setState({ welcomePopupShow: false })} /> */}

              {!isPageLoading && stocksSubscription && !!secIdParam && isSecIdValid && (
              <AddAlertPopup
                show={secIdParam !== undefined || secIdParam !== null}
                isAlertSettingsSetup={isAlertSettingsSetup}
                history={history}
                addedList={stocksSubscriptionIds}
                isModalSmall
                handleClose={this.hideAlertPopup}
                handleSubmit={this.handleAddAlertFromPopup}
              />
              )}

              <AddSectorPopup
                isDisabledSubmit={!selectedSectors.length}
                show={this.state.sectorPopupShow}
                handleClose={this.hideSectorPopup}
                handleSubmit={this.submitSectorPopup}
              />

              {/* Toast container */}
              <ToastContainer position="bottom-left" hideProgressBar />
            </div>
          ) : <div className="db-alert-page">Access forbidden, please login!</div>
        }
      </>

    );
  }
}

const mapStateToProps = state => ({
  stockList: getStocks(state),
  isUserAuthenticated: state.rootReducer.isAuthenticated,
  isPageLoading: state.rootReducer.isPageLoading,
  isAlertLoading: state.alertsReducer.isAlertLoading,
  isAuthLoading: state.rootReducer.isAuthLoading,
  isAuth: state.rootReducer,
  subscriptionError: state.stocksReducer.error,
  stocksSubscription: getUserSubscription(state),
  stocksSubscriptionIds: getStockSubscriptionIds(state),
  isAlertSettingsSetup: getIsAlertSettingSetup(state),
  showWelcomePopup: getShowWelcome(state),
  selectedSectors: getSelectedSector(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmitAlertSettings: () => dispatch(alerts.submitAlertSettings()),
  onSetupFirstAlertSettings: () => dispatch(alerts.setFirstAlertSetting()),
  onSubscribeToStock: data => dispatch(stocks.subscribeToStockAlert(data)),
  // onGetStockSubcription: () => dispatch(stocks.getSubscribedStockAlerts()),
  onGetSavedSettings: () => dispatch(alerts.getSavedAlertSettings()),
  onRemoveSubscribedStock: secIds => dispatch(stocks.removeStockSubscription(secIds)),
  onGetUserSSO: () => dispatch(getIsAuthenticated()),
  onAuthFail: () => dispatch(authFail()),
  onGetStocks: () => dispatch(stocks.fetchStocksByRole()),
  onGetSubscribedStocks: () => dispatch(stocks.getSubscribedStockAlerts()),
  onGetSectors: stocksSubscription => dispatch(sectorsAction.fetchSectorsByRole(stocksSubscription)),
});

export default track({
  page: 'Alert',
})(connect(mapStateToProps, mapDispatchToProps)(LayoutWithNavSidebar(Alert, Sidebar)));
