/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes, { object, string } from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { Button, Spin } from 'components';
import './StockList.scss';
import StockHeader from './StockHeader';
import StockItem from './StockItem';
import EmptyRows from './EmptyRows';

class StockList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cookColumn: props.columns,
      currentSort: { field: _.head(props.columns).field, sort: _.head(props.columns).sort },
      selectAll: false,
      selected: [],
    };
  }

  orderByStockNameAndTicker = ({ field, sort }) => {
    const { columns } = this.props;
    this.setState({
      currentSort: { field, sort },
      cookColumn: columns.map(item => ({
        ...item,
        sort: field == item.field ? sort : item.sort,
      })),
    });
  }

  /**
   * Check if stock is still available in the stock list
   * */
  isSubscribedStockInStockList = (secId) => {
    const stockList = this.props.stocks;
    if (stockList.includes(secId)) return false;
    return true;
  }

  /**
   * Select individual stock method
   */
  handleSelect = (secId) => {
    const { selected } = this.state;

    if (!selected.includes(secId)) {
      this.setState(prevState => ({
        selected: [...prevState.selected, secId],
      }));
    } else {
      // const remove = selected.slice(selected.indexOf(secId) > -1);
      const filteredList = selected.filter(x => x !== secId);
      this.setState({
        selected: filteredList,
      });
    }
  }

  /**
   * Select All Subscribed stocks method
   */
  handleSelectAll = () => {
    const { selectAll } = this.state;
    const { stocksSubscriptionIds } = this.props;

    this.setState(prev => ({
      selectAll: !prev.selectAll,
    }));

    if (!selectAll) {
      if (stocksSubscriptionIds && Array.isArray(stocksSubscriptionIds)) {
        this.setState({
          selected: stocksSubscriptionIds,
        });
      }
    } else {
      this.setState({
        selected: [],
      });
    }
  }

  /** Check if stock is selected */
  isSelected = secId => (!!this.state.selected.includes(secId));

  handleGroupStocksRemove = () => {
    this.props.onGroupStocksRemove(this.state.selected);

    /** clear selection after submission */
    this.setState({ selected: [], selectAll: false });
  }

  render() {
    const {
      rows, onStockRemove, onGroupStocksRemove, isPageLoading,
    } = this.props;
    const {
      cookColumn, currentSort, selected, selectAll,
    } = this.state;
    const classes = classNames({
      'nw-stock-list': true,
    });

    return (
      <div className={classes}>
        <StockHeader
          columns={cookColumn}
          sort={option => this.orderByStockNameAndTicker(option)}
          showRemoveBtn={selected && Array.isArray(selected) && selected.length !== 0}
          // onSelectedStockRemove={() => onGroupStocksRemove(this.state.selected)}
          onSelectedStockRemove={() => this.handleGroupStocksRemove()}
          onSelectAll={() => this.handleSelectAll()}
          isSelectAll={selectAll}
        />

        {isPageLoading && <div className="page-loading"><Spin /></div> }

        {!isPageLoading && (
          <section className={rows && rows.length === 0 ? 'nw-stock-list__emptyContainer' : ''}>
            {rows && rows.length !== 0 && (_.orderBy(rows, [currentSort.field], [currentSort.sort])).map((row, rowIndex) => (
              <StockItem
                rowItem={row}
                columns={cookColumn}
                id={`row-${rowIndex}`}
                key={rowIndex}
                secId={row.secId}
                onRemove={onStockRemove}
                isDisabled={this.isSubscribedStockInStockList(row.secId)}
                onSelect={() => this.handleSelect(row.secId)}
                isChecked={this.isSelected(row.secId)}
              />
            ))}

            {rows && rows.length === 0 && <EmptyRows />}
          </section>
        )}

        {selected && Array.isArray(selected) && selected.length !== 0 && <Button className="nw-stock-list__btnRemoveStocks" onButtonClick={() => onGroupStocksRemove(this.state.selected)} value="Delete selected stock(s)" isSecondary isRounded />}
      </div>
    );
  }
}

StockList.propTypes = {
  /** columns data */
  columns: PropTypes.arrayOf(object),
  /** rows data */
  rows: PropTypes.arrayOf(object),
  /** stocks from backend */
  stocks: PropTypes.arrayOf(object),
  /** on remove stock */
  onStockRemove: PropTypes.func,
  /** onGroupStocksRemove */
  onGroupStocksRemove: PropTypes.func,
  /** subscription ids */
  stocksSubscriptionIds: PropTypes.arrayOf(string),
};

StockList.defaultProps = {
  columns: [],
  rows: [],
  stocks: [],
  onStockRemove: () => {},
  onGroupStocksRemove: () => {},
  stocksSubscriptionIds: [],
};

export default StockList;
