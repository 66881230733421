import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './StockList.scss';
import CheckMark from 'components/CheckMark';

const StockItem = ({
  rowItem, columns, id, secId, onRemove, isDisabled, onSelect, isChecked,
}) => {
  const classes = classNames({
    'nw-stock-item': true,
    'nw-stock-item--disabled': rowItem.isDisabled,
  });

  // useEffect(() => {
  //   onSelect(isSelected);
  // }, [onSelect, isSelected]);

  const handleRemove = () => {
    onRemove(secId);
  };
  // const handleSelect = () => {
  //   setSelected(!isSelected);
  // };
  return (
    <div className={classes}>
      <div className="nw-stock-item__col">
        <CheckMark id={id} name={rowItem.secId} type="checkbox" isChecked={isChecked} onCheckMarkChange={onSelect} />
      </div>
      {columns.map((column, columnIndex) => (
        <div
          className={`nw-stock-item__col ${column.customClass} ${!isDisabled && column.disabledClass}`}
          key={columnIndex}
          style={{ width: [column.width] }}
        >
          {rowItem[column.field]}
        </div>
      ))}
      <div className="nw-stock-item__col nw-stock--action">
        <button type="button" className={`stock-button-close ${!isDisabled && 'stock-button-close--showAlways'}`} onClick={handleRemove}>
          {!isDisabled && <span>This stock has been dropped. Remove now!</span>}
        </button>
      </div>
    </div>
  );
};

StockItem.propTypes = {
  /** remove stock */
  onRemove: PropTypes.func,
  /** is stock disabled? used for stock that has been removed */
  isDisabled: PropTypes.bool,
  /** On selection of stock */
  onSelect: PropTypes.func,
  /** is stock selected */
  isChecked: PropTypes.bool,
};

StockItem.defaultProps = {
  onRemove: () => {},
  isDisabled: false,
  onSelect: () => {},
  isChecked: false,
};

export default StockItem;
