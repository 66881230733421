import { TRACKING_EVENT, TRACKING_KEY, TRACKING_VALUE } from 'shared/constants';

export const trackVisitAlert = (tracking, callback = () => {}) => {
  const body = {
    event: TRACKING_EVENT.ACCESS_PAGE,
    key: TRACKING_KEY.PAGE,
    value: TRACKING_VALUE.MY_ALERT,
    index: true,
    complex: false,
  };
  tracking.trackEvent({ body, callback });
};

export const trackAddStock = (tracking, stock, key, callback = () => {}) => {
  const body = {
    event: TRACKING_EVENT.ADD_STOCKS,
    key,
    value: stock,
    index: true,
    complex: false,
  };
  tracking.trackEvent({ body, callback });
};

export const trackSearchStock = (tracking, type, callback = () => {}) => {
  const body = {
    event: TRACKING_EVENT.TYPING,
    key: TRACKING_KEY.SEARCH_STOCKS,
    value: type,
    index: true,
    complex: false,
  };
  tracking.trackEvent({ body, callback });
};

export const trackingEmailClickedMA = (tracking, id, callback = () => {}) => {
  const body = {
    event: TRACKING_EVENT.MY_ALERT,
    key: TRACKING_KEY.EMAIL_CLICKED,
    value: `id=${id}`,
    index: true,
    complex: true,
  };
  tracking.trackEvent({ body, callback });
};
