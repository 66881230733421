import {
  getStocksByRole, postSubscribeToStock, getSubscribedStocks, deleteSubscribedStocks,
} from 'shared/services/api';
import _ from 'lodash';
import ACTION_TYPES from './actionTypes';
import sectors from './sectors';

const pageLoading = isPageLoading => ({
  type: ACTION_TYPES.PAGE_LOADING,
  payload: { isPageLoading },
});

const fetchStocksSuccess = stocks => ({
  type: ACTION_TYPES.GET_MS_STOCKS_SUCCESS,
  payload: { stocks },
});

const fetchStocksFail = error => ({
  type: ACTION_TYPES.GET_MS_STOCKS_FAILURE,
  payload: { error },
});

/**
 * Fetch stock by user Role
 */
const fetchStocksByRole = () => (dispatch, getState) => new Promise((resolve) => {
  const { rootReducer: { role, authError } } = getState();
  dispatch(pageLoading(true));
  if (!authError) {
    const data = [];
    getStocksByRole(role)
      .then((res) => {
        // console.log('stocks data ', res);
        const { stocks } = res;
        if (Array.isArray(stocks)) {
          stocks.forEach((stock) => {
            data.push({
              secId: stock.secId,
              name: stock.name,
              ticker: stock.ticker,
              sectorName: stock.sectorName,
              // msStarRating: stock.equityResearchStarRating,
            });
          });

          dispatch(fetchStocksSuccess(data));
          dispatch(pageLoading(false));
        }

        resolve(data);
      })
      .catch((error) => {
        dispatch(fetchStocksFail(error));
        dispatch(pageLoading(false));
      });
  }
});

const subscribeToAlertSuccess = stocksSubscription => ({
  type: ACTION_TYPES.SUBSCRIBE_TO_STOCK_ALERT_SUCCESS,
  payload: { stocksSubscription },
});

const subscribeToAlertFail = error => ({
  type: ACTION_TYPES.SUBSCRIBE_TO_STOCK_ALERT_FAIL,
  payload: { error },
});

const removeStockSubscriptionSuccess = stocksSubscription => ({
  type: ACTION_TYPES.REMOVE_STOCK_SUBSCRIPTION_SUCCESS,
  payload: { stocksSubscription },
});

const removeStockSubscriptionFail = error => ({
  type: ACTION_TYPES.REMOVE_STOCK_SUBSCRIPTION_FAIL,
  payload: { error },
});

/**
 * Delete subscribed stock
 * @param {*} arrOfStrings
 */
const removeStockSubscription = arrOfStrings => (dispatch, getState) => new Promise((resolve, reject) => {
  const { stocksSubscription } = getState().stocksReducer;
  // dispatch(pageLoading(true));
  deleteSubscribedStocks(arrOfStrings)
    .then(() => {
      if (Array.isArray(arrOfStrings)) {
        let stocksAfterDelete = [...stocksSubscription];
        for (let i = 0; i < arrOfStrings.length; i += 1) {
          stocksAfterDelete = _.remove(stocksAfterDelete, item => item.secId !== arrOfStrings[i]);
        }
        dispatch(removeStockSubscriptionSuccess(stocksAfterDelete));
        dispatch(sectors.refreshStocksBySector());
      }
      // dispatch(pageLoading(false));
      resolve();
    }).catch((err) => {
      dispatch(removeStockSubscriptionFail(err));
      // dispatch(pageLoading(false));
      reject();
    });
});

/**
 * Subscribe to stocks for alerts
 * Submit array of objects
 * @param {*} arrOfObj
 */
const subscribeToStockAlert = arrOfObj => dispatch => new Promise((resolve, reject) => {
  // const data = Object.assign({}, { stocks: arrOfObj });
  // console.log('data for stock submission ', arrOfObj);
  dispatch(pageLoading(true));

  postSubscribeToStock(arrOfObj).then(() => {
    dispatch(subscribeToAlertSuccess(arrOfObj));
    dispatch(sectors.refreshStocksBySector());
    dispatch(pageLoading(false));
    resolve();
  }).catch((err) => {
    dispatch(subscribeToAlertFail(err));
    dispatch(pageLoading(false));
    reject();
  });
});

const getSubscriptionSuccess = stocksSubscription => ({
  type: 'GET_STOCK_SUBSCRIPTION_SUCCESS',
  payload: { stocksSubscription },
});

const getSubscriptionFail = error => ({
  type: ACTION_TYPES.GET_STOCK_SUBSCRIPTION_FAIL,
  payload: { error },
});

/**
 * Get Saved Subscription from backend
 */
const getSubscribedStockAlerts = () => dispatch => new Promise((resolve, reject) => {
  dispatch(pageLoading(true));
  getSubscribedStocks()
    .then((res) => {
      // console.log('saved subscription ', res);
      dispatch(getSubscriptionSuccess(res));
      dispatch(pageLoading(false));
      resolve(res);
    }).catch((err) => {
      dispatch(getSubscriptionFail(err));
      dispatch(pageLoading(false));
      reject(err);
    });
});


const stocks = {
  fetchStocksByRole,
  subscribeToStockAlert,
  getSubscribedStockAlerts,
  removeStockSubscription,
  pageLoading,
};

export default stocks;
