import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spin } from 'components';
import './PageLoader.scss';

const PageLoader = (props) => {
  const classes = classNames({
    'page-loader': true,
    'page-loader--loading': props.isLoading,
  });
  return (
    <div className={classes}>
      <Spin spinning />
    </div>
  );
};

PageLoader.propTypes = {
  /** isLoading */
  isLoading: PropTypes.bool,
};

PageLoader.defaultProps = {
  isLoading: false,
};

export default PageLoader;
