import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Widget, Switch, AlertSettings } from 'components';
import { alerts } from 'shared/redux/actions';
import { ROLE } from 'shared/constants';

class Sidebar extends Component {
  // componentDidMount() {
  //   const { isAlertSettingsSetup, onGetSavedSettings } = this.props;
  //   if (isAlertSettingsSetup) onGetSavedSettings();
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * check if there is a change in props & there is alert setting setup
     * if yes, submit changes to backend (save settings)
     * */
    if (snapshot !== null && prevProps.alertSettings !== snapshot && this.props.isAlertSettingsSetup) {
      // console.log('snapchange ', prevProps.alertSettings, snapshot);
      this.props.onSubmitAlertSettings();
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.alertSettings !== null && prevProps.alertSettings !== this.props.alertSettings) {
      return this.props.alertSettings;
    }
    return null;
  }

  /**
   * make user role capitalize and plural
   */
  parsedUserRole = (userRole) => {
    switch (userRole) {
      case 'poweruser':
        return 'Power Users';
      default:
        return 'Advisors';
    }
  }

  render() {
    const {
      isReceiveEmailNotification, onToggleGeneralSetting,
      // onSaveAlertSettings, alertSettings,
      isAlertSettingsSetup, userRole,
      isReceiveDailyBriefing, onToggleDailyBriefing,
    } = this.props;

    // console.log('FROM DB ', alertSettings);
    console.log('user role ', userRole, ROLE.ADVISOR);
    return (
      <div>
        <Widget title="General Settings" headingLevel="h4">
          <Switch
            id="general-setting"
            label={(
              <span>
                I want to receive email notification for new stocks added into the
                {' '}
                <strong>{this.parsedUserRole(userRole)}</strong>
                {' '}
                portal
              </span>
            )}
            isChecked={isReceiveEmailNotification}
            onSwitchChange={onToggleGeneralSetting}
          />


          <Switch
            id="daily-briefing"
            label={(
              <span>
                  I want to receive daily summary email before market opening, with all stock alerts on
                {' '}
                <strong>Advisors</strong>
                {' '}
                portal
              </span>
              )}
            isChecked={isReceiveDailyBriefing}
            onSwitchChange={onToggleDailyBriefing}
          />

        </Widget>
        {isAlertSettingsSetup && (
        <Widget title="Email Alert Settings" headingLevel="h4" description="This personalisation applies to all stocks.">
          <AlertSettings
            formName="alert-widget"
            // onSubmit={(state) => {
            //   console.log('------ ', state);
            //   onSaveAlertSettings(state);
            // }}
            // {...alertSettings}
          />
        </Widget>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isReceiveEmailNotification: state.alertsReducer.alertSettings.isReceiveEmailNotification,
  isReceiveDailyBriefing: state.alertsReducer.alertSettings.isReceiveDailyBriefing,
  isAlertSettingsSetup: state.alertsReducer.isAlertSettingSetup,
  alertSettings: state.alertsReducer.alertSettings,
  userRole: state.rootReducer.role,
});

const mapDispatchToProps = dispatch => ({
  onToggleGeneralSetting: () => dispatch(alerts.toggleGeneralSetting()),
  onToggleDailyBriefing: () => dispatch(alerts.toggleDailyBriefing()),
  onSubmitAlertSettings: () => dispatch(alerts.submitAlertSettings()),
  // onGetSavedSettings: () => dispatch(alerts.getSavedAlertSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
