import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SearchInput.scss';
import { Button } from 'components';
// import _ from 'lodash';

let searchTimer = null;

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      filter: '',
      filteredData: [],
      // data: demoResultData,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    clearTimeout(searchTimer);
  }

  onProcessSearching = (filter) => {
    const { data } = this.props;
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      const lowercasedFilter = filter.toLowerCase();
      const filteredData = data.filter(item => Object.keys(item).some(key => item[key].toLowerCase().includes(lowercasedFilter)));
      this.setState({ filteredData });

      const isSearchByTicker = data.map(item => ({ ticker: item.ticker })).filter(item => item.ticker.toLowerCase().includes(lowercasedFilter));
      if (isSearchByTicker.length) {
        this.props.trackSearchStock('ticker');
      } else {
        this.props.trackSearchStock('name');
      }
    }, 1000);
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleInputChange = (event) => {
    const filter = event.target.value;

    // The filter is not affect to the search typing / integrate tracker
    this.onProcessSearching(filter);

    this.setState({
      searching: true,
      filter,
    });
  }

  handleClearFilter = () => {
    this.setState({
      filter: '',
      searching: false,
    });

    this.searchInput.focus();
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        searching: false,
      });
    }
  }

  handleAddAlert = (secId) => {
    const {
      onAddAlert,
      // isAlertSettingsSetup
    } = this.props;

    onAddAlert(secId);
    this.setState({
      filter: '',
      searching: false,
    });
  }

  isStockonSubscription = (secId) => {
    const subscription = this.props.addedList;
    if (!secId) return false;
    return subscription.includes(secId);
  }

  render() {
    const {
      inputPlaceHolder, hasCloseButton,
    } = this.props;
    const { filter, filteredData } = this.state;
    const classes = classNames({
      'nw-search-input': true,
      'nw-search-input--show': this.state.searching && filteredData.length > 0,
    });

    return (
      <div className={classes} ref={this.setWrapperRef}>
        <div className="nw-search-input__input">
          <div className="nw-search-input__input-input">
            <input
              type="text"
              id="nw-search-input-stocks"
              placeholder={inputPlaceHolder}
              value={this.state.filter}
              onChange={this.handleInputChange}
              ref={(input) => { this.searchInput = input; }}
            />
          </div>
          {hasCloseButton && this.state.searching && filter
            && (
            <div className="nw-search-input__input-close">
              <Button isIcon name="close" onButtonClick={this.handleClearFilter} />
            </div>
            )
          }
        </div>
        {(this.state.searching && filteredData.length > 0) ? (
          <div className="nw-search-input__dropdown">
            <div className="nw-search-result">
              {
                filteredData.map((item, index) => (
                  <div className="nw-search-result__item" key={index}>
                    <div className="nw-search-result__content">
                      <span className="nw-search-result__name"><span title={item.name}>{item.name}</span></span>
                      <span className="nw-search-result__ticker">{item.ticker}</span>
                    </div>
                    <div className="nw-search-result__actions">
                      <Button
                        value={this.isStockonSubscription(item.secId) ? 'Added' : 'Add Alert'}
                        isDisabled={this.isStockonSubscription(item.secId)}
                        isPrimary
                        isSmall
                        isRounded
                        onButtonClick={() => this.handleAddAlert(item)}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ) : <></>}
      </div>
    );
  }
}

SearchInput.propTypes = {
  /** input placeholder */
  inputPlaceHolder: PropTypes.string,
  /** show close button */
  hasCloseButton: PropTypes.bool,
  /** data */
  data: PropTypes.arrayOf(PropTypes.object),
  /** Add alert function */
  onAddAlert: PropTypes.func,
  /** addedList */
  addedList: PropTypes.arrayOf(String),
};

SearchInput.defaultProps = {
  inputPlaceHolder: 'Add a stock by searching Stock name or Ticker',
  hasCloseButton: false,
  data: [],
  onAddAlert: () => {},
  addedList: [],
};

export default SearchInput;
