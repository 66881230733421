import ACTION_TYPES from '../actions/actionTypes';

const sectorsReducer = (state = [], action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_MS_SECTORS_SUCCESS:
      return { ...state, sectors: action.payload.sectors };

    case ACTION_TYPES.GET_MS_SECTORS_FAILURE:
      return { ...state, error: action.payload.error };

    case ACTION_TYPES.SELECT_A_MS_SECTOR:
      return { ...state, sectors: action.payload.sectors };

    case ACTION_TYPES.SELECT_ALL_MS_SECTOR:
      return { ...state, sectors: action.payload.sectors };

    default:
      return state;
  }
};

export default sectorsReducer;
