import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Widget.scss';
import SubHeader from 'components/SubHeader';

const Widget = ({
  title,
  headingLevel,
  description,
  children,
}) => {
  const classes = classNames({
    'nw-widget': true,
  });
  return (
    <div className={classes}>
      {title && <SubHeader title={title} headingLevel={headingLevel} description={description} />}
      {children}
    </div>
  );
};

Widget.propTypes = {
  /** content */
  children: PropTypes.node,
};

Widget.defaultProps = {
  children: null,
};

export default Widget;
