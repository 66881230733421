import { getSectorsByRole } from 'shared/services/api';
import _ from 'lodash';
import ACTION_TYPES from './actionTypes';

const selectAMSSector = id => (dispatch, getState) => {
  const { sectorsReducer } = getState();
  const cookSector = _.mapValues(sectorsReducer.sectors, (items) => {
    if (items.isAllChecked) {
      return {
        ...items,
        value: [...items.value.map(item => ({ ...item, isChecked: item.isAdded ? item.isChecked : item.secId === id }))],
        isAllChecked: false,
      };
    }
    return {
      ...items,
      value: [...items.value.map(item => ({ ...item, isChecked: item.secId === id ? !item.isChecked : item.isChecked }))],
      isAllChecked: false,
    };
  });
  return dispatch({
    type: ACTION_TYPES.SELECT_A_MS_SECTOR,
    payload: {
      sectors: cookSector,
    },
  });
};

const selectAllMSSectors = sec => (dispatch, getState) => {
  const { sectorsReducer } = getState();
  const cookSector = _.mapValues(sectorsReducer.sectors, (items, key) => {
    if (key === sec) {
      return {
        ...items,
        value: [...items.value.map(item => ({ ...item, isChecked: item.isAdded ? item.isChecked : !items.isAllChecked }))],
        isAllChecked: !items.isAllChecked,
      };
    }
    return { ...items };
  });
  return dispatch({
    type: ACTION_TYPES.SELECT_ALL_MS_SECTOR,
    payload: { sectors: cookSector },
  });
};

const fetchSectorsSuccess = sectors => ({
  type: ACTION_TYPES.GET_MS_SECTORS_SUCCESS,
  payload: { sectors },
});

const fetchSectorsFail = error => ({
  type: ACTION_TYPES.GET_MS_SECTORS_FAILURE,
  payload: { error },
});

const parseStocksInSectors = (sectors, stocksSubscription) => {
  const cookSubscription = stocksSubscription.map(st => (st.secId));
  const cookSectors = _.mapValues(sectors, (items) => {
    const cookItems = (items.value || items).map(item => ({ ...item, isChecked: false, isAdded: cookSubscription.includes(item.secId) }));
    return {
      value: [...cookItems],
      isAllChecked: false,
      isAllAdded: !cookItems.filter(att => !att.isAdded).length,
    };
  });
  return cookSectors;
};

/**
 * @TODO fetch only when authenticated (improve the condition)
 */
const fetchSectorsByRole = stocksSubscription => (dispatch, getState) => new Promise((resolve) => {
  const { rootReducer: { role, authError } } = getState();
  if (!authError) {
    getSectorsByRole(role)
      .then((res) => {
        dispatch(fetchSectorsSuccess(parseStocksInSectors(res, stocksSubscription)));
        resolve();
      })
      .catch(error => dispatch(fetchSectorsFail(error)));
  }
});

const refreshStocksBySector = () => (dispatch, getState) => new Promise((resolve) => {
  const { stocksReducer: { stocksSubscription }, sectorsReducer: { sectors } } = getState();
  dispatch(fetchSectorsSuccess(parseStocksInSectors(sectors, stocksSubscription)));
  resolve();
});

const sectors = {
  fetchSectorsByRole,
  selectAMSSector,
  selectAllMSSectors,
  refreshStocksBySector,
};

export default sectors;
