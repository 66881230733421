/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Logo from 'assets/images/logo-default@2x.png';
import LogoMobile from 'assets/icons/favicon.png';
import { connect } from 'react-redux';
import {
  gotoMSPage,
} from '../../App.utils';
import './LayoutWithNavSidebar.scss';

const Navbar = ({ token, targetUrl }) => (
  <nav className="db-hoc-navbar">
    <div className="db-hoc-navbar__title">
      <div onClick={() => gotoMSPage(token, targetUrl)}>
        <h2>Morningstar Equity Research Portal</h2>
      </div>
    </div>
    <ul className="db-hoc-navbar__nav">
      <li className="active">
        <span>My Alerts</span>
      </li>
    </ul>
    <div className="db-hoc-navbar__brand">
      <img className="db-hoc-navbar__brand__desktop" src={Logo} alt="Deutsche Bank" />
      <img className="db-hoc-navbar__brand__mobile" src={LogoMobile} alt="Deutsche Bank" />
    </div>
  </nav>
);

const mapStateToProps = state => ({
  token: state.rootReducer.token,
  targetUrl: state.rootReducer.targetUrl,
});

export default connect(
  mapStateToProps,
  null,
)(Navbar);
