import React from 'react';
import { CheckMark } from 'components';
import PropTypes from 'prop-types';
import './Sector.scss';

const SubContent = ({
  sectorName, subContentData, onSelectAMSSector, onSelectAllMSSectors,
}) => (
  <div className="nw-sector-box">
    <div className="nw-sector-box__header">
      <div className="nw-sector-data-title">
        <div className="nw-sector-data-title__item">
          <CheckMark
            type="checkbox"
            id={`sector-data-item-${subContentData.length}`}
            isChecked={subContentData.isAllChecked}
            onCheckMarkChange={() => onSelectAllMSSectors(sectorName)}
            isDisabled={subContentData.isAllAdded}
          />
        </div>
        <div className="nw-sector-data-title__item">Name</div>
        <div className="nw-sector-data-title__item">Ticker</div>
      </div>
    </div>
    <div className="nw-sector-box__body">
      <div
        className="nw-sector-data-list"
        id="nw-sector-data-list"
      >
        {
            subContentData.value.map((item, itemIndex) => (
              <div className={`nw-sector-data-list__item ${item.isAdded ? 'nw-sector-data-list__item--disabled' : ''}`} key={itemIndex}>
                <div className="nw-sector-data-list__col">
                  <CheckMark
                    type="checkbox"
                    id={`sector-data-item-${itemIndex}`}
                    onCheckMarkChange={() => onSelectAMSSector(item.secId)}
                    isChecked={item.isChecked}
                    isDisabled={item.isAdded}
                  />
                </div>
                <div className="nw-sector-data-list__col">{item.name}</div>
                <div className="nw-sector-data-list__col">{item.ticker}</div>
                {item.isAdded ? <small className="nw-sector-data-list__col--related"><em>{item.isAdded && 'Added'}</em></small> : <></>}
              </div>
            ))
            }
      </div>
    </div>
  </div>
);
SubContent.propTypes = {
  /** name of the selected sector */
  sectorName: PropTypes.string,
  /* sub content data */
  subContentData: PropTypes.shape({
    isAllChecked: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.object),
  }),
  /** Setect a sector */
  onSelectAMSSector: PropTypes.func,
  /** Select all sector */
  onSelectAllMSSectors: PropTypes.func,
};

SubContent.defaultProps = {
  sectorName: '',
  subContentData: null,
  onSelectAMSSector: () => {},
  onSelectAllMSSectors: () => {},
};

export default SubContent;
