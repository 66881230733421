import React, { Component } from 'react';
import track from 'react-tracking';
import { Button, Input, Spin } from 'components';
import { LayoutAuth } from 'layouts';
import { resetUserPassword, changeUserPassword, signUserOut } from 'shared/redux/actions/auth';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { PATH, STORAGE_KEY } from 'shared/constants';
import './ForgotPassword.scss';
import _ from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { trackRoles } from '../../../App.tracking';
import { gotoMSPage } from '../../../App.utils';

const bodyClass = 'page-auth';

const toastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

class ForgotPassword extends Component {
  state = {
    email: '',
    password: '',
    isLoading: false,
  };

  componentDidMount() {
    document.body.classList.add(bodyClass);
  }

  componentWillUnmount() {
    document.body.classList.remove(bodyClass);
  }

  handleInputChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }

  handleSubmitEmail = async (e) => {
    // event.persist();
    e.preventDefault();

    const { onSubmitEmail } = this.props;
    const { email } = this.state;

    if (!isEmail(email)) {
      toast.error('Invalid email! ', toastOptions);
      return;
    }

    if (email.toLowerCase() === 'dbequityresearch@morningstar.com'.toLowerCase()) {
      toast.error('You cannot change/reset password', toastOptions);
      this.setState({ isLoading: false });
      return;
    }

    if (!_.isEmpty(email) && isEmail(email)) {
      this.setState({ isLoading: true });
      await onSubmitEmail(email)
        .then(() => {
          this.setState({ isLoading: false });
          toast.success('Reset Success! Check your email for new password.', toastOptions);
        }).catch(() => {
          this.setState({ isLoading: false });
          toast.error('Email does not exist!', toastOptions);
        });
    }
  }

  handleSubmitPassword = async (e) => {
    // event.persist();
    e.preventDefault();

    const {
      onSubmitPassword, onSignUserOut, location, tracking,
    } = this.props;
    const { password } = this.state;
    if (!password) {
      toast.error('Please enter password! ', toastOptions);
      return;
    }
    this.setState({ isLoading: true });

    const {
      userId, targetUrl, userRole, username, token,
    } = JSON.parse(localStorage.getItem(STORAGE_KEY.USER_INFO));
    await onSubmitPassword(userId, password)
      .then(() => {
        // sign out
        onSignUserOut().then(() => {
          /** Avoid duplicate tracking user role after login */
          if (location.state && location.state.isChangePassword) {
            /** tracking user roles */
            trackRoles(tracking, { username, roles: userRole }, () => {
              this.clearStorage();
              gotoMSPage(token, targetUrl);
            });
          } else {
            this.clearStorage();
            gotoMSPage(token, targetUrl);
          }
        });
      }).catch(() => {
        this.setState({ isLoading: false });
        toast.error('An error occurred!', toastOptions);
      });
  }

  goToLogin = () => {
    this.props.history.push(PATH.LOGIN);
  }

  clearStorage = () => {
    /** clear the user info after change password */
    localStorage.setItem(STORAGE_KEY.USER_INFO, JSON.stringify({}));
    /** */
    toast.success('Change password Success!', toastOptions);
  }

  render() {
    const { isChangePassword, location: { state } } = this.props;

    const buttonValue = isChangePassword ? 'Update password' : 'Reset password';

    if (state && (state.fromLogin || state.isChangePassword)) {
      return (
        <div className="db-forgot-page">
          <div className="db-forgot-page__content">
            <h1 className="title">
              {isChangePassword ? 'Change your password' : 'Forgot your password?'}
            </h1>

            <form onSubmit={(e) => {
              if (isChangePassword) {
                this.handleSubmitPassword(e);
              } else {
                this.handleSubmitEmail(e);
              }
            }}
            >
              <Input
                label={`${isChangePassword ? 'Password' : 'Email Address'}`}
                name={`${isChangePassword ? 'password' : 'email'}`}
                type={`${isChangePassword ? 'password' : 'email'}`}
                placeholder={`${isChangePassword ? 'Enter Password' : 'Enter email address'}`}
                onInputChange={this.handleInputChange}
                value={`${isChangePassword ? this.state.password : this.state.email}`}
              />

              <footer className={
                `db-auth-page__content__action ${isChangePassword ? 'db-auth-page__content__action--single-button' : ''}`
                }
              >
                <div>
                  {!isChangePassword ? <Button value="Back to Log In" isLink onButtonClick={this.goToLogin} /> : <></>}
                  <Button
                    type="submit"
                    value={this.state.isLoading ? <Spin /> : buttonValue}
                    isDisabled={this.state.isLoading}
                    isPrimary
                  />
                </div>
              </footer>
            </form>

            <ToastContainer />
          </div>
        </div>
      );
    }
    return <></>;
  }
}


const mapDispatchToProps = dispatch => ({
  onSubmitEmail: email => dispatch(resetUserPassword(email)),
  onSubmitPassword: changeUserPassword,
  onSignUserOut: signUserOut,
});

export default track({
  page: 'ChangePassword',
})(connect(
  null,
  mapDispatchToProps,
)(LayoutAuth(ForgotPassword)));
