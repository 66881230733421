import React, { Component } from 'react';
import { Modal, Sector } from 'components';
import PropTypes from 'prop-types';

class AddSectorPopup extends Component {
  render() {
    const {
      show, handleClose, handleSubmit, isDisabledSubmit,
    } = this.props;
    return (
      <Modal
        show={show}
        subtitle="Add a stock alert based on"
        title="Sector"
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        buttonSubmitLabel="Add Alert"
        isSmallSpacing
        isDisabledSubmit={isDisabledSubmit}
      >
        <Sector />
      </Modal>
    );
  }
}

AddSectorPopup.propTypes = {
  /** modal show */
  show: PropTypes.bool,
  /** handle close function */
  handleClose: PropTypes.func,
  /** handle submit function */
  handleSubmit: PropTypes.func,
  /** disable the popup submit button if there is no selected item */
  isDisabledSubmit: PropTypes.bool,
};

AddSectorPopup.defaultProps = {
  show: false,
  handleClose: () => {},
  handleSubmit: () => {},
  isDisabledSubmit: false,
};

export default AddSectorPopup;
