import React from 'react';

const IconSprites = () => {
  const IconSpritesStyle = {
    width: 0,
    height: 0,
    position: 'absolute',
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={IconSpritesStyle}
    >
      <defs>
        <symbol id="nw-icon--close" viewBox="0 0 8 8">
          <path d="M14.686,7.494l-3.193,3.194,3.194,3.194-.805.805-3.2-3.194L7.493,14.687l-.806-.805,3.194-3.194L6.687,7.494l.806-.806,3.194,3.194,3.194-3.194Z" transform="translate(-6.687 -6.688)" />
        </symbol>
        <symbol id="nw-icon--close" viewBox="0 0 14 14">
          <path d="m12.422,1.82l-7.856,7.355l-3.011,-2.767l-1.555,1.524l4.52,4.248l0.019,0l9.461,-8.868l-1.578,-1.492z" />
        </symbol>
      </defs>
    </svg>
  );
};

export default IconSprites;
