import React from 'react';
import { Modal } from 'components';

const WelcomePopup = props => (
  <Modal
    show={props.show}
    isSmall
    hideCloseButton
    isCenter
    handleSubmit={props.onHandleOk}
  >
    <h2>Welcome to My Alert page!</h2>
    <p>
        To start monitoring stocks, you can search by stock individually then add alert or
        search stocks by industry sectors then select multiple stocks to add alert.
    </p>
  </Modal>
);

export default WelcomePopup;
