import { TRACKING_EVENT, TRACKING_KEY } from 'shared/constants';


export const trackDevice = (tracking, device, callback = () => {}) => {
  const body = {
    event: TRACKING_EVENT.PAYLOAD,
    key: TRACKING_KEY.DEVICE,
    value: device,
    index: true,
    complex: false,
  };
  tracking.trackEvent({ body, callback });
};

export const trackBrowser = (tracking, browser, callback = () => {}) => {
  const body = {
    event: TRACKING_EVENT.PAYLOAD,
    key: TRACKING_KEY.BROWSER,
    value: browser,
    index: true,
    complex: false,
  };
  tracking.trackEvent({ body, callback });
};

export const trackCountry = (tracking, res, callback = () => {}) => {
  const body = {
    event: TRACKING_EVENT.PAYLOAD,
    key: TRACKING_KEY.COUNTRY,
    value: `country=${res.country_name}|continent=${res.continent_name}|ip=${res.ip}`,
    index: true,
    complex: true,
  };
  tracking.trackEvent({ body, callback });
};

export const trackRoles = (tracking, data, callback = () => {}) => {
  const body = {
    event: TRACKING_EVENT.PAYLOAD,
    key: TRACKING_KEY.ROLES,
    value: data.roles[0],
    user: data.username,
    index: true,
    complex: false,
  };
  tracking.trackEvent({ body, callback });
};

export const trackDBOffice = (tracking, data, callback = () => {}) => {
  const body = {
    event: TRACKING_EVENT.PAYLOAD,
    key: TRACKING_KEY.DBOFFICE,
    value: data.dbOffice,
    user: data.username,
    index: true,
    complex: false,
  };
  tracking.trackEvent({ body, callback });
};
