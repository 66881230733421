import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getIsAuthenticated,
//   authFail,
} from 'shared/redux/actions';
import { Spin } from 'components';
import './Homepage.scss';
// import { onTrackApp, gotoMSPage } from '../../App.utils';

class Homepage extends Component {
  componentDidMount() {
    const {
      onGetUserSSO,
      // isUserAuthenticated,
      // token,
      // tracking,
      // targetUrl,
    } = this.props;

    // if (isUserAuthenticated && token && targetUrl) {
    //   onTrackApp(tracking, () => gotoMSPage(token, targetUrl));
    // }
    onGetUserSSO().catch(() => this.props.history.push('/login'));
  }

  render() {
    return (
      <div className="db-home-page">
        <Spin />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUserAuthenticated: state.rootReducer.isAuthenticated,
  token: state.rootReducer.token,
  targetUrl: state.rootReducer.targetUrl,
});

const mapDispatchToProps = dispatch => ({
  onGetUserSSO: () => dispatch(getIsAuthenticated()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
