import React from 'react';
import PropTypes from 'prop-types';
import LoaderIcon from 'assets/icons/oval.svg';
import ReactSVG from 'react-svg';
import './Spin.scss';

const Spin = ({ spinning, children }) => (
  <div className={`nw-spinner ${spinning ? 'nw-spinner--spinning' : ''}`}>
    <ReactSVG src={LoaderIcon} className="nw-spinner__loading" />
    {children}
  </div>
);

Spin.propTypes = {
  // if spinner is spinning
  // eslint-disable-next-line react/no-unused-prop-types
  spinning: PropTypes.bool,
  children: PropTypes.node,
};

Spin.defaultProps = {
  spinning: true,
  children: null,
};

export default Spin;
