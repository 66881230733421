import ACTION_TYPES from '../actions/actionTypes';

const alertsReducer = (state = [], action) => {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_ALERT_GENERAL_SETTING:
      return { ...state, alertSettings: { ...state.alertSettings, isReceiveEmailNotification: !state.alertSettings.isReceiveEmailNotification } };
      // return { ...state, isReceiveEmailNotification: !state.isReceiveEmailNotification };
    case ACTION_TYPES.TOGGLE_ALERT_DAILY_BRIEFING:
      return { ...state, alertSettings: { ...state.alertSettings, isReceiveDailyBriefing: !state.alertSettings.isReceiveDailyBriefing } };

    case ACTION_TYPES.SET_FIRST_ALERT_SETTINGS:
      return { ...state, isAlertSettingSetup: !state.isAlertSettingSetup };

    case ACTION_TYPES.SAVE_MORNING_START_RATING:
      return { ...state, alertSettings: { ...state.alertSettings, isMorningStarRatingActive: !state.alertSettings.isMorningStarRatingActive } };

    case ACTION_TYPES.SAVE_RESEARCH_PUBLICATION:
      return {
        ...state,
        alertSettings: {
          ...state.alertSettings,
          isResearchPublicationActive: !state.alertSettings.isResearchPublicationActive,
          isReportsActive: !state.alertSettings.isResearchPublicationActive,
          isNotesActive: !state.alertSettings.isResearchPublicationActive,
          isFairValueActive: false,
          isUncertaintyActive: false,
          isEconomicMoatActive: false,
        },
      };

    case ACTION_TYPES.SAVE_PUBLICATION_REPORTS:
      return {
        ...state,
        alertSettings: {
          ...state.alertSettings,
          isReportsActive: action.isReportsActive,
          isUncertaintyActive: false,
          isEconomicMoatActive: false,
        },
      };

    case ACTION_TYPES.SAVE_PUBLICATION_NOTES:
      return { ...state, alertSettings: { ...state.alertSettings, isNotesActive: action.isNotesActive } };

    case ACTION_TYPES.SAVE_PUBLICATION_FAIR_VALUE:
      return { ...state, alertSettings: { ...state.alertSettings, isFairValueActive: !state.alertSettings.isFairValueActive } };

    case ACTION_TYPES.SAVE_PUBLICATION_UNCERTAINTY:
      return { ...state, alertSettings: { ...state.alertSettings, isUncertaintyActive: !state.alertSettings.isUncertaintyActive } };

    case ACTION_TYPES.SAVE_PUBLICATION_ECONOMIC_MOAT:
      return { ...state, alertSettings: { ...state.alertSettings, isEconomicMoatActive: !state.alertSettings.isEconomicMoatActive } };

    case ACTION_TYPES.ALERT_SETTINGS_SAVE:
      return {
        ...state,
        alertSettings: {
          ...state.alertSetting,
          isReceiveEmailNotification: action.payload.isReceiveEmailNotification,
          isMorningStarRatingActive: action.payload.isMorningStarRatingActive,
          isResearchPublicationActive: action.payload.isResearchPublicationActive,
          isReceiveDailyBriefing: action.payload.isReceiveDailyBriefing,
          isReportsActive: action.payload.isReportsActive,
          isNotesActive: action.payload.isNotesActive,
          isFairValueActive: action.payload.isFairValueActive,
          isUncertaintyActive: action.payload.isUncertaintyActive,
          isEconomicMoatActive: action.payload.isEconomicMoatActive,
        },
      };

    case ACTION_TYPES.ALERT_SETTINGS_GET_SUCCESS:
      return {
        ...state,
        generalSetting: action.payload.generalSetting,
        alertSetting: action.payload.alertSetting,
      };

    case ACTION_TYPES.ALERT_SETTINGS_GET_FAILURE:
      return { ...state, error: action.payload.error };

    case ACTION_TYPES.ALERT_SETTING_LOADING:
      return { ...state, isAlertLoading: action.payload.isAlertLoading };

    default:
      return state;
  }
};

export default alertsReducer;
