/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import _ from 'lodash';

/** Get Stocks unfiltered */
const getIsAlertSettingSetup = state => state.alertsReducer.isAlertSettingSetup;

/** Get Stocks unfiltered */
const getStocks = state => state.stocksReducer.stocks;

const getUserSubscription = state => state.stocksReducer.stocksSubscription;

const getSectors = state => state.sectorsReducer.sectors;

/**
 * Get Ids of Stock Subscriptions
 */
const getStockSubscriptionIds = createSelector(
  [getUserSubscription],
  (subscription) => {
    const ids = [];
    if (!subscription || !Array.isArray(subscription)) return [];
    subscription.forEach(stock => ids.push(stock.secId));
    return ids;
  },
);

const getShowWelcome = createSelector(
  [getIsAlertSettingSetup, getUserSubscription],
  (isAlertSetup, subscriptions) => {
    if (((!isAlertSetup && !subscriptions) || (subscriptions === undefined)) || (subscriptions && Array.isArray(subscriptions) && subscriptions.length === 0)) return true;
    return false;
  },
);

/**
 * Get stock by SEC ID
 */
const getStockBySecId = (secId, state) => {
  const allStocks = getStocks(state);
  if (!allStocks) return {};

  const filteredStock = allStocks.find(stock => stock.secId === secId);
  // console.log('selected stock ', filteredStock);
  return filteredStock;
};

const getSelectedSector = (state) => {
  const sectors = _.flatten(Object.values(getSectors(state)).map(items => ([...items.value])));
  return sectors.filter(item => item.isChecked).map(item => _.omit(item, ['isChecked', 'isAdded']));
};

export {
  getStocks,
  getIsAlertSettingSetup,
  getStockBySecId,
  getUserSubscription,
  getStockSubscriptionIds,
  getShowWelcome,
  // getStocksWithSubscription,
  getSelectedSector,
};
