import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './CheckMark.scss';

const CheckMark = ({
  type, id, name, label, isChecked, isDisabled, hasContainer, onCheckMarkChange,
}) => {
  const classes = classNames({
    'nw-checkmark': true,
    'nw-checkmark--has-label': label,
    'nw-checkmark--disabled': isDisabled,
    'nw-checkmark--has-container': hasContainer,
  });
  return (
    <div className={classes}>
      <label
        htmlFor={id}
      >
        <input
          id={id}
          type={type}
          name={name}
          className="nw-checkmark__input"
          checked={isChecked}
          disabled={isDisabled}
          onChange={onCheckMarkChange}
        />
        <span className="nw-checkmark__container">
          <span className="nw-checkmark__switcher" />
          {label
            && <span className="nw-checkmark__label">{label}</span>
          }
        </span>
      </label>
    </div>
  );
};

CheckMark.propTypes = {
  /** checkmark type */
  type: PropTypes.oneOf(['radio', 'checkbox']),
  /** checkmark id */
  id: PropTypes.string,
  /** checkmark name */
  name: PropTypes.string,
  /** checkmark label */
  label: PropTypes.node,
  /** is checkmark checked */
  isChecked: PropTypes.bool,
  /** is checkmark disabled */
  isDisabled: PropTypes.bool,
  /** has container */
  hasContainer: PropTypes.bool,
  /** on checkmark change */
  onCheckMarkChange: PropTypes.func,
};

CheckMark.defaultProps = {
  type: 'radio',
  id: '1',
  name: 'name',
  label: null,
  isChecked: null,
  isDisabled: false,
  hasContainer: false,
  onCheckMarkChange: () => {},
};

export default CheckMark;
