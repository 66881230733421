import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Modal.scss';
import Button from 'components/Button';

const Modal = ({
  subtitle,
  title,
  tickerCode,
  children,
  show,
  buttonCancelLabel,
  buttonSubmitLabel,
  handleClose,
  handleSubmit,
  backdropDismiss,
  isSmall,
  isCenter,
  isSmallSpacing,
  hideCloseButton,
  isDisabledSubmit,
}) => {
  const classes = classNames({
    'nw-modal': true,
    'nw-modal--open': show,
    'nw-modal--small': isSmall,
    'nw-modal--center': isCenter,
    'nw-modal--small-spacing': isSmallSpacing,
  });

  return (
    <div className={classes}>
      <div className="nw-modal__dialog">
        <div className="nw-modal__content">
          <div className="nw-modal__header">
            {subtitle
              && <h6 className="nw-modal__subtitle">{subtitle}</h6>
            }
            {title
              && (
              <h3 className="nw-modal__title">
                {title}
                <small>{tickerCode}</small>
              </h3>
              )
            }
          </div>
          <div className="nw-modal__body">
            {children}
          </div>
          <div className="nw-modal__footer">
            {!hideCloseButton && <Button isRounded isLink value={buttonCancelLabel} onButtonClick={handleClose} />}
            <Button isDisabled={isDisabledSubmit} isPrimary isRounded value={buttonSubmitLabel} onButtonClick={handleSubmit} />
          </div>
        </div>
      </div>
      <div
        className="nw-modal__overlay"
        onClick={backdropDismiss ? handleClose : undefined}
        onKeyPress={backdropDismiss ? handleClose : undefined}
        role="button"
        tabIndex="0"
      />
    </div>
  );
};

Modal.propTypes = {
  /** modal subtitle */
  subtitle: PropTypes.string,
  /** modal title */
  title: PropTypes.string,
  /** modal ticker code */
  tickerCode: PropTypes.string,
  /** modal content */
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.node,
  /** is show */
  show: PropTypes.bool,
  /** button cancel label */
  buttonCancelLabel: PropTypes.string,
  /** button submit label */
  buttonSubmitLabel: PropTypes.string,
  /** modal close handle */
  handleClose: PropTypes.func,
  /** modal submit handle */
  handleSubmit: PropTypes.func,
  /** the modal will be dismissed when the backdrop is clicked. */
  backdropDismiss: PropTypes.bool,
  /** is small size */
  isSmall: PropTypes.bool,
  /** is center alignment */
  isCenter: PropTypes.bool,
  /** hide close button */
  hideCloseButton: PropTypes.bool,
  /** is submit button disabled */
  isDisabledSubmit: PropTypes.bool,
};

Modal.defaultProps = {
  subtitle: null,
  title: null,
  tickerCode: null,
  children: null,
  show: false,
  buttonCancelLabel: 'Cancel',
  buttonSubmitLabel: 'Ok',
  handleClose: () => {},
  handleSubmit: () => {},
  backdropDismiss: true,
  isSmall: false,
  isCenter: false,
  hideCloseButton: false,
  isDisabledSubmit: false,
};

export default Modal;
