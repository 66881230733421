import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Switch.scss';

const Switch = ({
  label, isChecked, isDisabled, onSwitchChange, id, name,
}) => {
  const classes = classNames({
    'nw-switch': true,
    'nw-switch--has-label': label,
    'nw-switch--disabled': isDisabled,
  });
  return (
    <label
      htmlFor={id || name}
      className={classes}
    >
      <input id={id || name} type="checkbox" className="nw-switch__input" name={name} checked={isChecked} disabled={isDisabled} onChange={onSwitchChange} />
      <span className="nw-switch__switcher" />
      {label
        && <span className="nw-switch__label">{label}</span>
      }
    </label>
  );
};

Switch.propTypes = {
  /** switch ID */
  id: PropTypes.string,
  /** switch label */
  label: PropTypes.node,
  /** input name */
  name: PropTypes.string,
  /** is switch disasbled */
  isDisabled: PropTypes.bool,
  /** is switch checked */
  isChecked: PropTypes.bool,
  /** is switch on change */
  onSwitchChange: PropTypes.func,
};

Switch.defaultProps = {
  id: '1',
  label: null,
  name: null,
  isDisabled: false,
  isChecked: null,
  onSwitchChange: () => { },
};

export default Switch;
