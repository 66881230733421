import React, { Component } from 'react';
import track from 'react-tracking';
import './App.scss';
import { Provider } from 'react-redux';
import store from 'shared/redux/store';
// import { stocks, sectorsAction } from 'shared/redux/actions';
import { STORAGE_KEY } from 'shared/constants';
import { trackingService } from 'shared/services/api';
import IconSprites from 'components/Icon/IconSprites';
import Routes from './Routes';
import utils from './App.utils';


const APP_TRACKING = process.env.REACT_APP_TRACKING_TARGET;

class App extends Component {
  componentDidMount() {
    const { history, tracking } = this.props;
    utils.isUserLogin(history, tracking);

    // if (store.getState().rootReducer.role) {
    //   store.dispatch(stocks.fetchStocksByRole());
    //   store.dispatch(stocks.getSubscribedStockAlerts()).then((res) => {
    //     store.dispatch(sectorsAction.fetchSectorsByRole(res));
    //   });
    // }
  }

  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <Routes />
          <IconSprites />
        </div>
      </Provider>
    );
  }
}


const AppWithTracking = track({},
  {
    dispatch: ({ body, callback }) => {
      const userInfo = JSON.parse(localStorage.getItem(STORAGE_KEY.USER_INFO));
      if (userInfo && userInfo.username) {
        // eslint-disable-next-line prefer-destructuring
        body.user = userInfo.username;
      } else {
        body.user = body.user || 'anonymous';
      }
      body.app = APP_TRACKING;
      trackingService(body)
        .then(() => {
          callback();
        })
        .catch(() => {
          callback();
        });
    },
  })(App);

export default AppWithTracking;
