import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { sectorsAction } from 'shared/redux/actions';
import Button from 'components/Button';
import SubContent from './SubContent';
import './Sector.scss';

class Sector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectorSelected: '',
      filterSectors: '',
      filtering: false,
      pageWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setPageWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setPageWidth);
  }

  setPageWidth = () => {
    this.setState({
      pageWidth: window.innerWidth,
    });
  }

  handleSelectSector = (event) => {
    // scroll the sector list to the top anytime the use select a sector
    const sectorList = document.getElementById('nw-sector-data-list');
    sectorList.scrollIntoView({ behavior: 'smooth' });
    /** */
    this.setState({ sectorSelected: event.target.value });
  }

  handleFilterSector = (event) => {
    this.setState({
      filterSectors: event.target.value,
      filtering: true,
    });
  }

  handleClearFilterSector = () => {
    this.setState({
      filterSectors: '',
      filtering: false,
    });

    this.filterSectorInput.focus();
  }

  render() {
    const { sectors, onSelectAMSSector, onSelectAllMSSectors } = this.props;
    const {
      filtering, filterSectors, sectorSelected, pageWidth,
    } = this.state;
    // const sectorFirst = Object.keys(sectors)[0];
    const lowercasedFilter = filterSectors.toLowerCase();
    const filteredSectors = Object.keys(sectors).filter(item => item.toLowerCase().includes(lowercasedFilter));
    const sortedSectors = _.orderBy(filteredSectors, [], ['asc', 'desc']);
    const sectorFirst = sortedSectors[0];

    return (
      <div className="nw-sector">
        <div className="nw-sector__sidebar">
          <div className="nw-sector-box">
            <div className="nw-sector-box__header">
              <div className="nw-sector-filter">
                <div className="nw-sector-filter__input">
                  <div className="nw-sector-filter__input-input">
                    <input
                      type="text"
                      placeholder="Filter sector"
                      value={filterSectors}
                      onChange={this.handleFilterSector}
                      ref={(input) => { this.filterSectorInput = input; }}
                    />
                  </div>
                  {filtering && filterSectors
                    && (
                    <div className="nw-sector-filter__input-close">
                      <Button isIcon name="close" onButtonClick={this.handleClearFilterSector} />
                    </div>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="nw-sector-box__body">
              <div className="nw-sector-list">
                {sortedSectors && sortedSectors.map((sector, index) => (
                  sector === (sectorSelected || sectorFirst) ? (
                    <div className="nw-sector-list__item is-active" key={index}>
                      <button type="button" className="nw-sector-list__item-header">{sector}</button>
                      <div className="nw-sector-list__item-body">
                        {(isMobile || pageWidth < 768) && Object.keys(sectors).length ? (
                          <SubContent
                            sectorName={sectorSelected || sectorFirst}
                            onSelectAMSSector={onSelectAMSSector}
                            onSelectAllMSSectors={onSelectAllMSSectors}
                            subContentData={sectors[sectorSelected || sectorFirst]}
                          />
                        ) : <></>}
                      </div>
                    </div>
                  ) : (
                    <div className="nw-sector-list__item" key={index}>
                      <button type="button" className="nw-sector-list__item-header" value={sector} onClick={this.handleSelectSector}>
                        {sector}
                      </button>
                    </div>
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="nw-sector__content">
          {(!isMobile || pageWidth > 900) && Object.keys(sectors).length ? (
            <SubContent
              sectorName={sectorSelected || sectorFirst}
              onSelectAMSSector={onSelectAMSSector}
              onSelectAllMSSectors={onSelectAllMSSectors}
              subContentData={sectors[sectorSelected || sectorFirst]}
            />
          ) : <></>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sectors: state.sectorsReducer.sectors,
});

const mapDispathToProps = ({
  onSelectAMSSector: sectorsAction.selectAMSSector,
  onSelectAllMSSectors: sectorsAction.selectAllMSSectors,
});

export default connect(mapStateToProps, mapDispathToProps)(Sector);
