/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { ROLE } from 'shared/constants';
import utils from 'shared/helper/utils';
import throttle from 'lodash/throttle';
import rootReducer from '../reducer';
import { loadState, saveState } from './localStorage';

const persistedState = loadState();

export const initialState = {
  rootReducer: {
    authError: null,
    resetError: null,
    role: utils.getUserRoleOnLocalStorage() || (persistedState && persistedState.dbAuth && persistedState.dbAuth.role ? persistedState.dbAuth.role : null), // utils.getUserRoleOnLocalStorage(),
    token: persistedState && persistedState.dbAuth && persistedState.dbAuth.token ? persistedState.dbAuth.token : null,
    targetUrl: persistedState && persistedState.dbAuth && persistedState.dbAuth.targetUrl ? persistedState.dbAuth.targetUrl : null,
    isAuthenticated: persistedState && persistedState.dbAuth && persistedState.dbAuth.isAuthenticated ? persistedState.dbAuth.isAuthenticated : false,
    isPageLoading: false,
    isAuthLoading: false,
  },
  alertsReducer: {
    isAlertSettingSetup: false,
    alertSettings: {
      isReceiveEmailNotification: true,
      isReceiveDailyBriefing: true,
      isMorningStarRatingActive: true,
      isResearchPublicationActive: true,
      isReportsActive: true,
      isNotesActive: true,
      isFairValueActive: false,
      isUncertaintyActive: false,
      isEconomicMoatActive: false,
    },
    isAlertLoading: false,
  },
  stocksReducer: {
    stocks: [],
  },
  sectorsReducer: {
    sectors: [],
  },
};

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunkMiddleware)),
  persistedState,
);

store.subscribe(throttle(() => {
  saveState({
    dbAuth: store.getState().rootReducer,
  });
}, 500));

export default store;
