import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Icon.scss';

const Icon = ({
  name,
  size,
}) => {
  const classes = classNames({
    'nw-icon': true,
  });
  const iconSize = {
    width: size,
  };
  return (
    <svg className={classes} style={iconSize}>
      <use xlinkHref={`#nw-icon--${name}`} />
    </svg>
  );
};

Icon.propTypes = {
  /** icon name */
  name: PropTypes.string,
  /** icon size */
  size: PropTypes.number,
};

Icon.defaultProps = {
  name: null,
  size: 8,
};

export default Icon;
