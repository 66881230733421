/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import track from 'react-tracking';
import {
  Button, Input, PageLoader,
} from 'components';
import { LayoutAuth } from 'layouts';
import './Login.scss';
import { loginUser } from 'shared/redux/actions/auth';
import { getIsAuthenticated } from 'shared/redux/actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import {
  PATH, STORAGE_KEY,
} from 'shared/constants';
import _ from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { trackRoles, trackDBOffice } from '../../../App.tracking';
import {
  // onTrackApp,
  gotoMSPage,
} from '../../../App.utils';


const bodyClass = 'page-auth';

const toastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

class Login extends Component {
  state = {
    username: '',
    password: '',
    remember: false,
    isLoading: false,
  };

  componentDidMount() {
    document.body.classList.add(bodyClass);
    const {
      onGetUserSSO,
      // isUserAuthenticated, tracking, token, targetUrl,
    } = this.props;
    onGetUserSSO();
    // if (isUserAuthenticated && token && targetUrl) {
    //   onTrackApp(tracking, () => gotoMSPage(token, targetUrl));
    // }
  }

  componentWillUnmount() {
    document.body.classList.remove(bodyClass);
  }

  handleInputChange = (evt) => {
    if (evt.target.name === 'remember') {
      this.setState({
        [evt.target.name]: evt.target.checked,
      });
    } else {
      this.setState({
        [evt.target.name]: evt.target.value,
      });
    }
  }

  handleSubmit = async (e) => {
    // event.persist();
    e.preventDefault();
    const {
      onLogin,
      history,
      tracking,
    } = this.props;
    const {
      username, password, remember,
    } = this.state;

    if (username && !isEmail(username)) {
      toast.error('Invalid email address! ', toastOptions);
      return;
    }

    if (!username || !password) {
      toast.error(
        `Please enter ${!username ? 'username' : ''}${!username && !password ? '/' : ''}${!password ? 'password' : ''}! `,
        toastOptions,
      );
      return;
    }

    if (!_.isEmpty(username) && isEmail(username) && !_.isEmpty(password)) {
      this.setState({ isLoading: true });
      await onLogin(username, password, remember)
        .then((res) => {
          // prepare tracking the dbOffice
          if (res.dbOffice && res.dbOffice !== null && res.dbOffice !== '') {
            // track DB Office
            trackDBOffice(tracking, { username, dbOffice: res.dbOffice });
          }

          const userRes = {
            userId: res.userId,
            username: res.username,
            targetUrl: res.targetUrl,
            userRole: res.roles,
            token: res.token,
            mode: res.mode,
          };
          const trackRole = {
            username: res.username,
            roles: res.roles,
          };

          // store user info after login
          const storeData = JSON.stringify(userRes);
          localStorage.setItem(STORAGE_KEY.USER_INFO, storeData);

          /** Track user by roles */
          if (_.isNull(res.lastPasswordChange)) { // redirect to change password if this is first time loging
            localStorage.setItem(STORAGE_KEY.FIRST_TIME_LOGIN, true);
            /** */
            trackRoles(tracking, trackRole, () => {
              history.push({
                pathname: PATH.CHANGE_PASSWORD,
                state: {
                  fromLogin: true,
                },
              });
            });
            /** */
          } else {
            const prevLocation = history.location.state;
            console.log('location previous', prevLocation);

            /**
             * Check previous url and redirect accordingly
             */
            if (prevLocation && prevLocation.fromMyAlerts) {
              trackRoles(tracking, trackRole, () => history.push(PATH.ALERT));
            } else if (prevLocation && prevLocation.fromStock) {
              console.log('REDIRECT TO STOCK DETAIL', prevLocation);
              // gotoMSPage(res.token, res.targetUrl);
              trackRoles(tracking, trackRole, () => history.push(`${PATH.STOCK}/${history.location.state.secId}`));
            } else {
              console.log('REDIRECT TO MS TOOL!!!');
              trackRoles(tracking, trackRole, () => gotoMSPage(res.token, res.targetUrl));
            }
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
          toast.error('Invalid username/password! ', toastOptions);
        });
    }
  };

  goToForgotPassword = () => {
    this.props.history.push({
      pathname: PATH.FORGOT_PASSWORD,
      state: {
        fromLogin: true,
      },
    });
  }

  render() {
    // console.log('history ', this.props.history);
    return (
      <div className="db-login-page">
        <h1 className="title">
          Sign in to
          <br />
          Morningstar Equity Research Portal
        </h1>
        <form onSubmit={this.handleSubmit}>
          <Input
            label="Email Address"
            name="username"
            placeholder="Enter email address"
            onInputChange={this.handleInputChange}
            value={this.state.username}
          />
          <Input
            label="password"
            name="password"
            placeholder="Case sensitive password"
            type="password"
            onInputChange={this.handleInputChange}
            value={this.state.password}
          />

          <footer className="db-auth-page__content__action">
            <div>
              <Button value="Did you forget your password?" isLink onButtonClick={this.goToForgotPassword} />
              <Button
                type="submit"
                value={this.state.isLoading ? 'logging in...' : 'Log In'}
                isDisabled={this.state.isLoading}
                isPrimary
              />
            </div>
            <label htmlFor="remember_me">
              <input
                id="remember_me"
                type="checkbox"
                name="remember"
                onChange={this.handleInputChange}
                value={this.state.remember}
              />
              <span>Remember me</span>
            </label>
            <p>
              <b>Customer Service:</b>
&nbsp;If you have any questions regarding your subscription or general comments and suggestions, please email
              {' '}
              <a href="#">AsiaEquityResearchSupport@morningstar.com</a>
            </p>
          </footer>
        </form>

        <ToastContainer />
        <PageLoader isLoading={this.state.isLoading} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.rootReducer.authError,
  isUserAuthenticated: state.rootReducer.isAuthenticated,
  token: state.rootReducer.token,
  targetUrl: state.rootReducer.targetUrl,
});

const mapDispatchToProps = dispatch => ({
  onLogin: (username, password, remember) => dispatch(loginUser(username, password, remember)),
  onGetUserSSO: () => dispatch(getIsAuthenticated()),
});

export default track({
  page: 'Login',
})(connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutAuth(Login)));
